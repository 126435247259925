import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Radium from 'radium';
import * as ControlUserInterface from '../../actions/ControlUserInterface';

const styles = {
    dropMain: {
        'position': 'absolute',
        'transform': 'translate3d(0px, -174px, 0px)',
        'top': '0px',
        'left': '0px',
        'will-change': 'transform',
    },
    menuBlockItem: {
        cursor: 'pointer',
        width: '100%',
        ':hover': {
            borderTop: 'solid 1px black',
            borderBottom: 'solid 1px black',
          },
    },
};

class TemplateMessage extends Component {
    handelOpenTemplateMessage = () => {
        this.props.opneTemplateMessage();
    }
    handelSubpageTemplateMessage = key => {
        this.props.openSubpageTemplateMessage(key);
    }
    render() {
        return (
            <div className="dropup" style={{display: 'inline-block'}}>
                <div className="dropdown-toggle" data-toggle="dropdown" style={{cursor: 'pointer', color: '#9ba3b2'}}>
                    <i className="fa fa-book" title="快速回應" onClick={this.handelOpenTemplateMessage}/>
                </div>

                {this.props.showTemplateMessage &&
                    <ul className="dropdown-menu show" style={{ marginLeft: 3}}>
                        {this.props.TemplateMessage.map( (item, key) =>
                            <li
                                key={key+'TemplateMessage'}
                                className="dropup dropdown-submenu">
                                <div className="menublock" style={{cursor: 'pointer'}} onMouseEnter={() => this.handelSubpageTemplateMessage(key)}>
                                    {item.templateGroup}<span className="caret"></span>
                                </div>

                                <ul className="dropdown-menu subMenu" style={this.props.showPages[key].show?{display: 'block', top: -200}:null}>
                                    <li>
                                        {item.listTemplateContent.map( (itemContent,i) =>
                                            <div
                                                key={i+'listTemplateContent'+key}
                                                className="menublockContent"
                                                style={styles.menuBlockItem}
                                                onClick={() => this.props.concatTextValue(itemContent)}>
                                                {itemContent}
                                            </div>)
                                        }
                                    </li>
                                </ul>
                            </li>
                        )}
                    </ul>
                }

            </div>
        );
    }
}
const mapStateToProps = state => ({
    showTemplateMessage: state.ControlUserInterface.TemplateMessage,
    showPages: state.ControlUserInterface.TemplateMessagePage,
    TemplateMessage: state.Chat.TemplateMessage,
});
const mapDispatchToProps = dispatch => bindActionCreators({
    ...ControlUserInterface,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Radium(TemplateMessage));