import React, { Component, Fragment } from 'react';
import {connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Ezagent from '../../actions/Ezagent';
import { LoadingHide } from '../../actions/Loading';
import SearchBar from '../../components/Ezagent/SearchBar';
import Pagination from './Pagination';
import SearchListItem from '../../components/Ezagent/SearchListItem';
class SearchList extends Component {
    componentDidMount() {
        const {
            getSessionEzagent,
            SearchString,
            SearchKeyWordString,
            NowPage,
        } = this.props;
        getSessionEzagent(SearchString, NowPage, SearchKeyWordString);
    }
    componentWillUnmount() {
        const {initialDate} = this.props;
        initialDate();
    }
    componentDidUpdate(){
        const {LoadingHide} = this.props;
        LoadingHide();
    }
    render() {
        const {
            SearchResult,
            getMessage2,
            getSessionEzagent,
            changeSearchString,
            changeSearchKeyWordString,
            SearchString,
            SearchKeyWordString,
            TotalPage,
        } = this.props;

        const PropsToSearchBar = {
            getSessionEzagent,
            SearchString,
            SearchKeyWordString,
            changeSearchString,
            changeSearchKeyWordString,
        };
        return (
            <Fragment>
                <div className="col-lg-4 page-height">
                    <div className="style-1">
                        <SearchBar {...PropsToSearchBar} />
                        {TotalPage > 1 && <Pagination />}
                        {SearchResult.map((item, key) =>
                            <SearchListItem
                                key={key}
                                getMessage2={getMessage2}
                                SearchKeyWordString={SearchKeyWordString}
                                {...item} />
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    SearchResult: state.Ezagent.SearchResult,
    SearchString: state.Ezagent.SearchData.SearchString,
    SearchKeyWordString: state.Ezagent.SearchData.SearchKeyWordString,
    TotalPage: state.Ezagent.Pagination.TotalPage,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  ...Ezagent,
  LoadingHide,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);