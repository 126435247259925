import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Chat from '../../actions/Chat';

class PaginationContainer extends Component {
    createPaginationArray = (NowPage, TotalPage) => {
        if(TotalPage === 2) return [1, 2];
        else if(NowPage === 1) return [1, 2, 3];
        else if(NowPage === TotalPage) return [TotalPage-2, TotalPage-1, TotalPage];
        return [NowPage-1, NowPage, NowPage+1];
    };

    PaginationItem = (indexNumber, itemNumber, NowPage, getSessionHistory) => {
            return(
                <Fragment key={indexNumber}>
                    { itemNumber === NowPage ?
                        <li className="paginate_button page-item active"
                            style={{ cursor: 'pointer'}}
                            onClick={() => getSessionHistory(itemNumber)}>
                            <div className="page-link">{itemNumber}</div>
                        </li>
                        :
                        <li className="paginate_button page-item"
                            style={{ cursor: 'pointer'}}
                            onClick={() => getSessionHistory(itemNumber)}>
                            <div className="page-link">{itemNumber}</div>
                        </li>
                    }
                </Fragment>
            );
    }

    render() {
        const {
            getSessionHistory,
            Pagination: {
                NowPage,
                TotalPage,
            }
        } = this.props;

        return (
            <Fragment>
                { this.createPaginationArray(NowPage, TotalPage).map(
                    (item, index) => this.PaginationItem(index, item, NowPage, getSessionHistory)
                )}
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        Pagination: state.Chat.Pagination,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaginationContainer);