import Axios from 'axios';
import { API_CS_HOST } from '../static/config';
import { handleNetworkError } from './ErrorHandle';
import { FailModal } from './Modal';
import { LoadingHide, LoadingShow } from './Loading';

export const GET_ONLINE_MEMBER = 'CHAT/GET_ONLINE_MEMBER';
export const FETCH_GET_ONLINE_MEMBER_SUCCESS = 'CHAT/FETCH_GET_ONLINE_MEMBER_SUCCESS';
export const FETCH_GET_ONLINE_MEMBER_FAIL = 'CHAT/FETCH_GET_ONLINE_MEMBER_FAIL';
export const FETCH_GET_MESSAGE_SUCCESS = 'CHAT/FETCH_GET_MESSAGE_SUCCESS';
export const FETCH_GET_MESSAGE_FAIL = 'CHAT/FETCH_GET_MESSAGE_FAIL';
export const FETCH_GET_MoreMESSAGE_SUCCESS = 'CHAT/FETCH_GET_MoreMESSAGE_SUCCESS';
export const FETCH_GET_SESSION_HISTORY_SUCCESS = 'CHAT/FETCH_GET_SESSION_HISTORY_SUCCESS';
export const FETCH_GET_SESSION_HISTORY_FAIL = 'CHAT/FETCH_GET_SESSION_HISTORY_FAIL';
export const FETCH_GET_MEMBER_DATA_SUCCESS = 'CHAT/FETCH_GET_MEMBER_DATA_SUCCESS';
export const FETCH_GET_MEMBER_DATA_FAIL = 'CHAT/FETCH_GET_MEMBER_DATA_FAIL';
export const GRABPEOPLE_SUCCESS = 'CHAT/GRABPEOPLE_SUCCESS';
export const GRABPEOPLE_FAIL = 'CHAT/GRABPEOPLE_FAIL';
export const SENDMESSAGE_SUCCESS = 'CHAT/SENDMESSAGE_SUCCESS';
export const SENDMESSAGE_FAIL = 'CHAT/SENDMESSAGE_FAIL';
export const UPDATE_TO_READ_SUCCESS = 'CHAT/UPDATE_TO_READ_SUCCESS';
export const UPDATE_TO_READ_FAIL = 'CHAT/UPDATE_TO_READ_FAIL';
export const UPLOAD_IMAGE_SUCCESS = 'CHAT/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'CHAT/UPLOAD_IMAGE_FAIL';
export const WEI_XIN_TEMPLATE_SUCCESS = 'CHAT/WEI_XIN_TEMPLATE_SUCCESS';
export const WEI_XIN_TEMPLATE_FAIL = 'CHAT/WEI_XIN_TEMPLATE_FAIL';
export const GET_TEMPLATE_MESSAGE_SUCCESS = 'CHAT/GET_TEMPLATE_MESSAGE_SUCCESS';
export const GET_TEMPLATE_MESSAGE_FAIL = 'CHAT/GET_TEMPLATE_MESSAGE_FAIL';
export const Set_MessageButton = 'CHAT/Set_MessageButton';
export const INITIAL_DATA = 'CHAT/INITIAL_DATA';
export const CHANGE_INPUT_PAGE = 'CHAT/CHANGE_INPUT_PAGE';
export const INITIAL_STATUS = 'CHAT/INITIAL_STATUS';
export const TRANSFER_SUCCESS = 'CHAT/TRANSFER_SUCCESS';
export const TRANSFER_FAIL = 'CHAT/TRANSFER_FAIL';
export const OPEN_NOTICE_SOUND = 'CHAT/OPEN_NOTICE_SOUND';
export const CLOSE_NOTICE_SOUND = 'CHAT/CLOSE_NOTICE_SOUND';

const GetOnlineMember = `${API_CS_HOST}/Chat/GetOnlineMember`;
const GetSessionHistory = `${API_CS_HOST}/Chat/GetSessionHistory`;
const GetMessage = `${API_CS_HOST}/Chat/GetMessage`;
const GetMoreMessage = `${API_CS_HOST}/Chat/GetMoreMessage`;
const GetMemberData = `${API_CS_HOST}/Chat/GetMemberData`;
const GrabPeople = `${API_CS_HOST}/Chat/GrabPeople`;
const SendMessage = `${API_CS_HOST}/Chat/BackSendMessage`;
const UpdateToRead = `${API_CS_HOST}/Chat/BackUpdateToRead`;
const UploadImage = `${API_CS_HOST}/Chat/BackUploadImage`;
const WeiXinTemplate = `${API_CS_HOST}/Chat/WeiXinTemplate`;
const GetTemplate = `${API_CS_HOST}/Chat/GetTemplate`;
const Transfer = `${API_CS_HOST}/Chat/Transfer`;
const GetFeedbackSession = `${API_CS_HOST}/Chat/GetFeedbackSession`;
const GetFeedbackMessage = `${API_CS_HOST}/Chat/GetFeedbackMessage`;


Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.accessToken}` || null;

export const getOnlineMember = () => {
    return dispatch => {
            Axios.post(GetOnlineMember)
                .then(res => {
                    dispatch({type: FETCH_GET_ONLINE_MEMBER_SUCCESS, data: res.data});
                    // if(res.data.data.onlineMember.length > 0 ){
                    //     dispatch({type: OPEN_NOTICE_SOUND});
                    // }
                })
                .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const closeSound = () => ({
    type: CLOSE_NOTICE_SOUND,
});

export const getMessage = (id, roomToken,memberID) => {
    return (dispatch,getState) => {
        Axios({
            url: GetMessage,
            method: 'post',
            data: {
              sessionID: id,
              roomGuid: roomToken,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
        .then(res => {
            dispatch(getMemberData(memberID));
            dispatch( {type: FETCH_GET_MESSAGE_SUCCESS, data: res.data, MemberID: memberID} );
        })
        .catch(error => {
            dispatch(handleNetworkError(error.response));
            dispatch(LoadingHide());
        });
    };
};

export const getMoreMessage = (id, roomToken, moreMessageID) => {
    return dispatch => {
        Axios({
            url: GetMoreMessage,
            method: 'post',
            data: {
              sessionID: id,
              roomGuid: roomToken,
              moreMessageID,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
            .then(res => {
                dispatch( {type: FETCH_GET_MoreMESSAGE_SUCCESS, data: res.data} );
                dispatch(LoadingHide());
            })
            .catch(error => {
                dispatch(handleNetworkError(error.response));
                dispatch(LoadingHide());
            });
    };
};

export const changeInputPage = InputPage => ({
    type: CHANGE_INPUT_PAGE,
    InputPage,
});

export const getSessionHistory = nowPage => {
    return dispatch => {
        dispatch(LoadingShow());
        Axios({
            url: GetSessionHistory,
            method: 'post',
            data: {
                nowPage,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
        .then(res => {
            dispatch( {type: FETCH_GET_SESSION_HISTORY_SUCCESS, data: res.data} );
            dispatch(LoadingHide());
        })
        .catch(error => {
            dispatch(handleNetworkError(error.response));
            dispatch(LoadingHide());
        });
    };
};

export const getMemberData = MemberID => {
    return dispatch => {
        Axios({
            url: GetMemberData,
            method: 'post',
            data: {
                accountId: MemberID,
                accountCode: '',
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
        .then(res => {
            dispatch( {type: FETCH_GET_MEMBER_DATA_SUCCESS, data: res.data } );
            dispatch(LoadingHide());
        })
        .catch(error => {
            dispatch(handleNetworkError(error.response));
            dispatch(LoadingHide());
        });
    };
};

export const grabPeople = (csUserID, sessionID, roomGuid, memberAccount) => {
    return dispatch => {
        Axios({
            url: GrabPeople,
            method: 'post',
            data: {
                csUserID,
                sessionID,
                roomGuid,
                memberAccount,
            },
            config: { headers: { 'Connect-Type': 'application/json' } },
        })
            .then(res => {
                dispatch({type: GRABPEOPLE_SUCCESS, data: res.data});
                dispatch(LoadingHide());
            })
            .catch(error => {
                dispatch(handleNetworkError(error.response));
                dispatch(LoadingHide());
            });
    };
};

export const sendMessage = (inputText, memberData) => {
    return dispatch => {
        Axios({
            url: SendMessage,
            method: 'post',
            data: {
              sessionID: memberData.sessionID,
              roomGuid: memberData.roomGuid,
              fromID: memberData.userID,
              toID: memberData.memberID,
              fromType: 0,
              toType: 1,
              message: inputText,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
          })
            .then((res) => {
                if (res.data.statusCode === 0) {
                    dispatch( {type: SENDMESSAGE_SUCCESS, data: res.data} );
                  } else {
                    dispatch(FailModal(true, res.data.message));
                  }
            })
            .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const updateToReadSuccess = data => ({
    type: UPDATE_TO_READ_SUCCESS,
    data
});

export const updateToReadFail = error => ({
    type: UPDATE_TO_READ_FAIL,
    error
});

export const updateToRead = memberData => {
    return dispatch => {
        Axios({
            url: UpdateToRead,
            method: 'post',
            data: {
              sessionID: memberData.sessionID,
              roomGuid: memberData.roomGuid,
              fromType: 0,
              toType: 1,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
          })
            .then(res => dispatch(updateToReadSuccess(res.data)))
            .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const uploadImageSuccess = data => ({
    type: UPLOAD_IMAGE_SUCCESS,
    data,
});

export const uploadImageFail = error => ({
    type: UPLOAD_IMAGE_FAIL,
    error,
});

export const uploadImage = (formData) => {
    return dispatch => {
        Axios({
            method: 'post',
            url: UploadImage,
            data: formData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then((res) => {
            if (res.data.statusCode === 0) {
                dispatch(uploadImageSuccess(res.data));
              } else {
                dispatch(FailModal(true, res.data.message));
              }
        })
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const weiXinTemplateSuccess = data => ({
    type: WEI_XIN_TEMPLATE_SUCCESS,
    data,
});
export const weiXinTemplateFail = error => ({
    type: WEI_XIN_TEMPLATE_FAIL,
    error,
});
export const weiXinTemplate = () => {
    return dispatch => {
        Axios.get(WeiXinTemplate)
            .then(res => dispatch(weiXinTemplateSuccess(res.data)))
            .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const getTemplateSuccess = data => ({
    type: GET_TEMPLATE_MESSAGE_SUCCESS,
    data,
});
export const getTemplateFail = error => ({
    type: GET_TEMPLATE_MESSAGE_FAIL,
    error,
});
export const getTemplate = () => {
    return dispatch => {
        Axios.post(GetTemplate)
            .then(res => dispatch(getTemplateSuccess(res.data)))
            .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const initialData = () => ({
    type: INITIAL_DATA,
});

export const initialStatus = () => ({
    type: INITIAL_STATUS,
});

export const transfer = (sessionID, roomGuid, functionID) => {
    return dispatch => {
        Axios({
            url: Transfer,
            method: 'post',
            data: {
                sessionID,
                roomGuid,
                functionID,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const getFeedbackSession = inputNumber => dispatch => {
    dispatch(LoadingShow());
    Axios({
        url: GetFeedbackSession,
        method: 'post',
        data: {
            nowPage: inputNumber,
        },
        config: { headers: { 'Content-Type': 'application/json' } },
    })
    .then(res => {
        dispatch({type: FETCH_GET_SESSION_HISTORY_SUCCESS, data: res.data});
        dispatch(LoadingHide());
    })
    .catch(dispatch(LoadingHide()));
};

export const getFeedbackMessage = (sessionID, roomGuid,memberID) =>  (dispatch,getState) => {
    Axios({
        url: GetFeedbackMessage,
        method: 'post',
        data: {
            sessionID,
            roomGuid,
        },
        config: { headers: { 'Content-Type': 'application/json' } },
    })
    .then(res => {
        if(memberID !== undefined && (getState().Chat.MemberID === '' || getState().Chat.MemberID !== memberID)){
            dispatch(getMemberData(memberID));
            dispatch( {type: FETCH_GET_MESSAGE_SUCCESS, data: res.data, MemberID: memberID} );
        }
        else {
            dispatch( {type: FETCH_GET_MESSAGE_SUCCESS, data: res.data, MemberID: memberID} );
            dispatch(LoadingHide());
        };
    })
    .catch(dispatch(LoadingHide()));
};
