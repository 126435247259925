import {
    SEND_SMSCODE_SUCCESS,
    GET_AUTHSMSCODE_SUCCESS,
} from '../actions/MemberVerification.js';


const initialState = {
    statusCode: null,
    message: '',
    data: '',
};

const sendSMSCode = (state, action) => {
    const setData = {
      ...state,
    };
    alert(action.data.data);
    return setData;
};

const getAuthSMSCode = (state, action) => {
    const setData = {
      ...state,
    };
    alert(action.data.data);
    return setData;
};



const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SEND_SMSCODE_SUCCESS:
        return sendSMSCode(state, action);
      case GET_AUTHSMSCODE_SUCCESS:
        return getAuthSMSCode(state, action);
      default:
        return state;
    }
  };
  
  export default reducer;