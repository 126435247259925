import React, { Component, Fragment } from 'react';
import AnimateLoading from './AnimateLoading';

class SearchBar extends Component {
    state = {
        TimeCounter: 10,
        ShowTimer: false,
    };

    TimeCounterSubstraction = () => this.setState(prevState => ({ TimeCounter: prevState.TimeCounter - 1,}));

    TimeCounterInitial = () => this.setState({ TimeCounter: 10 });

    handleCheckbox = e => {
        const {
            getSessionHistory,
        } = this.props;
        if (e.target.checked) {
            this.setState({ ShowTimer: true });
            this.IntervalID = setInterval(() => {
                if(this.state.TimeCounter !== 0) this.TimeCounterSubstraction();
                else {
                    this.TimeCounterInitial();
                    getSessionHistory(this.props.SearchString, 1, this.props.searchKeyWordString);
                }
            },
            1000);
        } else {
            this.setState({ ShowTimer: false });
            clearInterval(this.IntervalID);
            this.TimeCounterInitial();
        }
    }

    handleChange = e => {
        const {
            changeSearchString,
        } = this.props;
        changeSearchString(e.target.value);
    }

    handleKeyPress = e => {
        const {
            getSessionHistory,
            SearchString,
            SearchKeyWordString,
        } = this.props;
        if(e.key === 'Enter') {
            getSessionHistory(SearchString, 1, SearchKeyWordString);
        }
    }

    handleClick = () => {
        const {
            getSessionHistory,
            SearchString,
            SearchKeyWordString,
        } = this.props;
        getSessionHistory(SearchString, 1, SearchKeyWordString);
    }

    handleKeyWordChange = e => {
        const {
            changeSearchKeyWordString,
        } = this.props;
        changeSearchKeyWordString(e.target.value);
    }

    render() {
        const {
            TimeCounter,
            ShowTimer,
        } = this.state;
        const {
            SearchString,
            SearchKeyWordString,
        } = this.props;
        return (
            <Fragment>
                <div className="text-chat-title text-center" style={{height:'200px'}}>
                    <span className="text-right">
                        搜寻条件 是否需自动更新
                        <input
                            type="checkbox"
                            onChange={this.handleCheckbox}
                            id="checkbox" />
                        <label id="AutoUpdSecond" style={{display: 'inherit'}}>
                            {ShowTimer && `${TimeCounter}秒更新`}
                        </label>
                    </span>
                    <div>
                        <input
                            type="text"
                            placeholder="會員帳號/會員名稱/客服帳號"
                            className="form-control"
                            value={SearchString}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            style={{ width: '100%' }} />
                    </div>
                    <div>
                        <input
                            type="text"
                            placeholder="關鍵字搜尋"
                            className="form-control"
                            value={SearchKeyWordString}
                            onChange={this.handleKeyWordChange}
                            onKeyPress={this.handleKeyPress}
                            style={{ width: '100%' }} />
                    </div>

                    <button
                        className="btn btn-info"
                        style={{width:'200px'}}
                        onClick={this.handleClick}
                        type="button">
                        搜尋
                    </button>
                </div>
            </Fragment>
        );
    }
}

export default SearchBar;