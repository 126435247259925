import React, { Component, Fragment } from 'react';
import moment from 'moment';

class SearchListItem extends Component {
    render() {
        const {
            createDt,
            lastText,
            memberAccount,
            getMessage,
            sessionID,
            roomGuid,
            memberName,
            memberID,
            SearchKeyWordString,
            isSessionEnd,
        } = this.props;
        return (
            <Fragment>
                <div onClick={() =>getMessage({sessionID, roomGuid, memberAccount, memberName, memberID, SearchKeyWordString})}>
                    <div className="row aChat">
                        <div className="col-md-2">
                            {isSessionEnd === 0 ? (
                                <span className="Icon_chat">{memberName[0]}</span>
                            ) : (
                                <span className="Icon_chat offline">{memberName[0]}</span>
                            )}
                        </div>
                        <div className="col-md-10">
                            <span className="text-name">{memberAccount}</span>
                            <span className="text-time">{moment.unix(createDt).format('MM/DD HH:mm')}</span>
                            <span className="text-content">{lastText}</span>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SearchListItem;