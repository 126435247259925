import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import WaitingList from './WaitingList.js';
import ChatList from './ChatList.js';
import MainChat from './MainChat.js';
import * as actionsChat from '../../actions/Chat';
import * as Member from '../../actions/Member';
import * as ControlUserInterface from '../../actions/ControlUserInterface';

class Chat extends Component {
    componentDidMount() {
        if(this.props.UserAccount === undefined) this.props.history.push("/Login");
    }
    componentDidUpdate() {
        if(this.props.UserAccount === undefined) this.props.history.push("/Login");
    }
    componentWillUnmount() {
        this.props.initialData();
    }
    closeAllUI = e => {
        if(this.props.showWeiXin) this.props.closeWeiXin();
        if(this.props.showTemplateMessage && e.target.nodeName !== 'A') this.props.closeTemplateMessage();
    }

    render() {
        return (
            <div className="content-wrapper" onClick={this.closeAllUI}>
                <div className="container-fluid" id="container">
                    <div className="row ListChat">
                        <WaitingList />
                        <ChatList />
                        <MainChat />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    showWeiXin: state.ControlUserInterface.WeiXin,
    showTemplateMessage: state.ControlUserInterface.TemplateMessage,
    UserAccount: state.Account.UserAccount,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...actionsChat,
    ...Member,
    ...ControlUserInterface,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Chat);