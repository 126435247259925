import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import * as Member from '../actions/Member';

const validate = values => {
    const errors = {};
    if (!values.Account) {
        errors.Account = '请输入帐号';
    }
    if(!values.Password) {
        errors.Password = '请输入密码';
    }
    return errors;
};

const renderField = ({ input, label, labelTitle, type, meta:{ touched, error, warning}}) => (
    <div className="form-group">
        <label htmlFor={label}>{labelTitle}</label>
        <input {...input} type={type} id={label} className="form-control text-box single-line" style={{width: '100%'}}/>
        {touched && ((error && <span className="field-validation-valid text-danger">{error}</span>))}
    </div>
);
class Login extends Component {
    state = {
        Account: '',
        Password: '',
    }
    componentDidUpdate() {
        if(this.props.UserAccount !== undefined) this.props.history.push("/");
    }

    getMemberToken = values => {
        this.props.getMemberToken({
            "account": values.Account,
            "password": values.Password,
        });
    }

    render() {
        return (
            <div className="bg-dark" style={{height: '100vh'}}>
                <div className="container" style={{maxWidth:'350px'}}>
                    <br />
                    <h1 style={{color:'#FFF', textAlign:'center', margin:'auto'}}>客服系統</h1>
                    <div className="card card-login mx-auto mt-5">
                        <div className="card-header">Login</div>
                        <div className="card-body">
                            <form onSubmit={this.props.handleSubmit(this.getMemberToken)}>
                                {this.props.ErrorMessage !== undefined &&
                                    <div className="validation-summary-errors text-danger">
                                        <ul>
                                            <li>{this.props.ErrorMessage}</li>
                                        </ul>
                                    </div>
                                }
                                <Field
                                    name="Account"
                                    type="text"
                                    component={renderField}
                                    label="Account"
                                    labelTitle="帐号"
                                />
                                <Field
                                    name="Password"
                                    type="password"
                                    component={renderField}
                                    label="Password"
                                    labelTitle="密码"
                                />
                                <input
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                    value="Login"
                                    disabled={this.props.submitting}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    fromValue: state.form.Login,
    UserAccount: state.Account.UserAccount,
    ErrorMessage: state.Account.ErrorMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Member,
}, dispatch);

export default reduxForm({form: 'Login', validate})(connect(mapStateToProps, mapDispatchToProps)(Login));