import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Chat from '../../actions/Chat';

class Profile extends Component {
    // componentDidMount() {
    //     const {
    //         getMemberData,
    //         memberData: {
    //             memberID,
    //         },
    //     } = this.props;
    //     if(memberID !== undefined)getMemberData(memberID);
    // }

    // shouldComponentUpdate(nextProps) {
    //     const {
    //         getMemberData,
    //         memberData: {
    //             memberID,
    //         },
    //     } = this.props;
    //     if(nextProps.memberData.memberID !== memberID) {
    //         getMemberData(nextProps.memberData.memberID);
    //         return false;
    //     }
    //     return true;
    // }

    setTransfer = (functionID) => {
        const {
            memberData: {
                isSessionEnd,
                roomGuid,
                sessionID,
            },
            transfer,
        } = this.props;
        if (isSessionEnd === 1)
            return;
        transfer(sessionID, roomGuid, functionID);
    }

    render() {
        const {
            memberData: {
                memberAccount,
                memberName,
            },

        } = this.props;
        return (
            <div className="col-md-3 text-center Chat-Content-Info" style={{ display: 'flex' }}>
                <span className="Icon_chat Icon_big">{memberName && memberName[0]}</span>
                <h3>{memberName && memberName}</h3>
                <div className="text-muted">
                    {memberAccount && memberAccount}
                    <br />

                </div>
                <br />
                {this.props.Account.PositionLevelID}
                <hr className="full-width" />
                {(this.props.Account.PositionLevelID !== 6 && this.props.Account.PositionLevelID !== 7) && (
                    <ul className="chatFun">
                        <li onClick={() => this.setTransfer(1)}>
                            <div style={{ cursor: 'pointer' }}>操作性問題</div>
                        </li>
                        <li onClick={() => this.setTransfer(2)}>
                            <div style={{ cursor: 'pointer' }}>轉接帳務</div>
                        </li>
                    </ul>
                )}
                <div className="text-details style-2" dangerouslySetInnerHTML={{ __html: this.props.Content }}>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    Content: state.Chat.Profile.Content,
    Account: state.Account,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Profile);