import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Chat from '../../actions/Chat';
import PaginationContainer from './PaginationContainer.jsx';

class Pagination extends Component {
    render() {
        const {
            getSessionHistory,
            changeInputPage,
            Pagination: {
                NowPage,
                TotalPage,
                InputPage,
            }
        } = this.props;
        return (
            <Fragment>
                <ul className="pagination" style={{display: 'flex'}}>
                    <li className="paginate_button page-item"
                        style={{ cursor: 'pointer'}}>
                        <input
                            type="number"
                            className="paginateInput"
                            value={InputPage}
                            onChange={(e)=>{
                                changeInputPage(e.target.value);
                                if( e.target.value !== '' && e.target.value <= TotalPage)
                                    getSessionHistory(e.target.value);
                            }}
                            min="1"
                            max={TotalPage} />
                    </li>
                    <li className="paginate_button page-item"
                        onClick={() => getSessionHistory(1)}
                        style={{ cursor: 'pointer'}}>
                        <div className="page-link" title="第一页">
                            <i className="fa fa-angle-double-left"></i>
                        </div>
                    </li>
                    <li className="paginate_button page-item"
                        onClick={() => getSessionHistory(NowPage-1)}
                        style={{ cursor: 'pointer'}}>
                        <div className="page-link" title="上一页">
                            <i className="fa fa-angle-left"></i>
                        </div>
                    </li>
                    <PaginationContainer />
                    <li className="paginate_button page-item"
                        onClick={() => getSessionHistory(NowPage+1)}
                        style={{ cursor: 'pointer'}}>
                        <div className="page-link" title="下一页">
                            <i className="fa fa-angle-right"></i>
                        </div>
                    </li>
                    <li className="paginate_button page-item"
                        onClick={() => getSessionHistory(TotalPage)}
                        style={{ cursor: 'pointer'}}>
                        <div className="page-link" title="最末页">
                            <i className="fa fa-angle-double-right"></i>
                        </div>
                    </li>
                </ul>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Pagination: state.Chat.Pagination,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Pagination);
