import { 
    createStore,
    compose,
    applyMiddleware
} from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/index';

const NODE_ENV = process.env.NODE_ENV || 'development';
const REDUX_DEVTOOLS = window.__REDUX_DEVTOOLS_EXTENSION__ ;

export default function getStore() {
    let composed = compose(applyMiddleware(
        thunk
    ));

    if (NODE_ENV !== 'production' && typeof REDUX_DEVTOOLS === 'function') {
        composed = compose(
            applyMiddleware(
            thunk
            ),
            REDUX_DEVTOOLS(),
        );
    }

    const store = createStore(
        reducers,
        {},
        composed,
    );

    return store;

}
