import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router-dom';
import { Switch, withRouter } from 'react-router';
import * as Member from '../actions/Member';
import * as ControlUserInterface from '../actions/ControlUserInterface';
import 'font-awesome/css/font-awesome.min.css';
import { NavLink } from 'react-router-dom';

const styles = {
    menuBack:{
        backgroundColor: 'rgb(24, 24, 24)',
        borderColor:'rgb(24, 24, 24)',
        marginRight:'30px',
        outline:'none',
    },
    menuIcon:{
        width: '30px',
        height: '4px',
        backgroundColor: '#ffff',
        margin: '6px 0',
    },
    menuContent:{
        position:'fixed',
        top:'50px',
        left:'0px',
        zIndex: "2000",
        backgroundColor:'#1a1a1a',
        width:'17%',
        height:'100%',
    },
    menuText:{
        textDecoration:"none",
        color:'#fff',
        fontSize:'18px',
        padding: "10px 15px 10px 30px",
        lineHeight: "40px",
    },
    menuIcon_new_1:{
        width: '30px',
        height: '4px',
        backgroundColor: '#ffff',
        margin: '6px 0',
        transform:"rotate(-45deg) translate(-8px,6px)",
        transition:"0.4s",
    },
    menuIcon_new_2:{
        opacity: 0,
    },
    menuIcon_new_3:{
        width: '30px',
        height: '4px',
        backgroundColor: '#ffff',
        margin: '6px 0',
        transform:"rotate(45deg)",
        transition:"0.4s",
    },
    menuOuter:{
        position: 'fixed',
        zIndex: '1000',
        width: '100%',
        height: '100%',
        top: '55px',
        left: '0',
        background: 'rgba(0, 0, 0, 0.8)',
    },
    menuMargin:{
        margin: '5px 0',
        border: '0.7px solid white',
    },
    nav:{
        textDecoration:"none",
    }
};

class MainNav extends Component {
    constructor() {
        super();
        this.state = {
          showMenu: false
        };
      };

    clossWeiXin = () => { if(this.props.showWeiXin) this.props.clossWeiXin(); }

    menuContent(){
        if(this.state.showMenu === true){
            return(
                <div>
                    <div style={styles.menuContent}>
                        <div style={styles.menuMargin} />
                        <NavLink
                            onClick={()=>{this.closeMenu()}}
                            exact
                            to="/"
                            activeStyle={{ textDecoration: "none" }}>
                            <div style={styles.menuText}>
                            <i className="fa fa-rocket" /> 抢单系统
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={()=>{this.closeMenu()}}
                            exact
                            to="/MemberVerification"
                            activeStyle={{ textDecoration: "none" }}>
                            <div style={styles.menuText}>
                            <i className="fa fa-check" /> 會員驗證
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={()=>{this.closeMenu()}}
                            exact
                            to="/Template"
                            activeStyle={{ textDecoration: "none" }}>
                            <div style={styles.menuText}>
                            <i className="fa fa-book" /> 快速回应
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={()=>{this.closeMenu()}}
                            exact
                            to="/History"
                            activeStyle={{ textDecoration: "none" }}>
                            <div style={styles.menuText}>
                            <i className="fa fa-history" /> 历史纪录
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={()=>{this.closeMenu()}}
                            exact
                            to="/Ezagent"
                            activeStyle={{ textDecoration: "none" }}>
                            <div style={styles.menuText}>
                            <i className="fa fa-history" /> 直属代理
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={()=>{this.closeMenu()}}
                            exact
                            to="/Range"
                            activeStyle={{ textDecoration: "none" }}>
                            <div style={styles.menuText}>
                            <i className="fa fa-file-text-o" /> 区间统计报表
                            </div>
                        </NavLink>
                        <NavLink
                            onClick={()=>{this.closeMenu()}}
                            exact
                            to="/CSV"
                            activeStyle={{ textDecoration: "none" }}>
                            <div style={styles.menuText}>
                            <i className="fa fa-file-text-o" /> 下载会员反馈
                            </div>
                        </NavLink>
                    </div>
                    <div style={styles.menuOuter} />
                </div>
            );
        }
    }

    closeMenu () {
        this.setState({showMenu: false})
    }

    toggleMenu () {
        this.setState({showMenu: !this.state.showMenu})
    }

    menuStatus(){
        if(this.state.showMenu === false){
            return(
                <div>
                    <button style={styles.menuBack}
                    onClick={()=>{this.toggleMenu()}}
                    alt="打开菜单">
                        <div style={styles.menuIcon}></div>
                        <div style={styles.menuIcon}></div>
                        <div style={styles.menuIcon}></div>
                    </button>
                </div>
            );
        }else{
            return(
                <div>
                    <button style={styles.menuBack}
                    onClick={()=>{this.toggleMenu()}}
                    alt="打开菜单">
                        <div style={styles.menuIcon_new_1}></div>
                        <div style={styles.menuIcon_new_2}></div>
                        <div style={styles.menuIcon_new_3}></div>
                    </button>
                </div>
            );
        }

    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark sidenav-toggled" id="mainNav" style={{marginBottom: 0}} onClick={this.clossWeiXin}>
                <div className="navbar-brand navbar-nav" style={{fontSize: '20px', lineHeight: '40px'}}>
                    <a href="/"><b>EZ 易利</b></a>
                </div>

                <ul className="navbar-nav">
                    <li className="nav-item">
                        <div>
                        {this.menuStatus()}
                        {this.menuContent()}
                        </div>
                    </li>
                    <li>
                    <Switch>
                        <Route exact path="/" render={() => (<h5>抢单系统</h5>)} />
                        <Route path="/MemberVerification" render={() => (<h5>會員驗證</h5>)} />
                        <Route path="/Template" render={() => (<h5>快速回应</h5>)} />
                        <Route path="/Range" render={() => (<h5>区间统计报表</h5>)} />
                        <Route path="/History" render={() => (<h5>历史纪录</h5>)} />
                        <Route path="/Ezagent" render={() => (<h5>直属代理</h5>)} />
                        <Route path="/CSV" render={() => (<h5>下载会员反馈</h5>)} />
                    </Switch>
                    </li>
                </ul>

                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <div className="nav-link">
                                {this.props.Account.PositionLevelID === 1? '主管 :' : '客服：'}
                                {this.props.Account.UserAccount}
                            </div>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                onClick={this.props.clearMemberToken}
                                to="/Login">
                                <div className="nav-link">
                                    <i className="fa fa-fw fa-sign-out"></i>Logout
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => ({
    Account: state.Account,
    showWeiXin: state.ControlUserInterface.WeiXin,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...ControlUserInterface,
    ...Member,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainNav));
