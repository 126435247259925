import React, { Component, Fragment } from 'react';
import {
    SortableElement,
    sortableHandle,
 } from 'react-sortable-hoc';
import ic_reorder_black from '../../images/ic_reorder_black_24px.svg';

const SortableImg = sortableHandle(() => (
    <img style={{cursor: 'row-resize'}} id="img1" src={ic_reorder_black} alt="img" />
));

class TableFromItem extends Component {
    render() {
        const {
            lineNumber,
            item: {
                content,
                id,
                tagName,
            },
            editPageObject: {
                switchEditPage,
                DELETE,
                MODIFY,
            },
            nowPage,
        } = this.props;
        return (
            <Fragment>
                <tr className="ui-sortable-handle">
                    <td style={{width:'3%'}}>
                        {lineNumber+1}
                    </td>
                    <td style={{width:'60%'}}>
                        {content}
                    </td>
                    <td style={{width:'20%'}}>
                        {tagName}
                    </td>
                    <td style={{width:'10%'}}>
                        <div
                            onClick={() => switchEditPage(MODIFY, id)}
                            style={{cursor: 'pointer', color: '#007bff', display: 'inline'}}>
                            编辑
                        </div> |
                        <div
                            onClick={() => {
                                const ensure = window.confirm('确定要删除吗？');
                                ensure && switchEditPage(DELETE, id, nowPage);
                            }}
                            style={{cursor: 'pointer', color: '#007bff', display: 'inline'}}>
                            删除
                        </div>
                    </td>
                    <td style={{width:'7%'}}>
                        <SortableImg />
                    </td>
                </tr>
            </Fragment>
        );
    }
}

export default SortableElement(TableFromItem);
