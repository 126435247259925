import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    values,
} from 'lodash';
import { SortableContainer } from 'react-sortable-hoc';
import {
    DELETE,
    MODIFY,
} from '../../reducers/Template.js';
import * as Template from '../../actions/Template.js';

import TableFromItem from '../../components/Template/TableFromItem';

class TableFromContainer extends Component {
    render() {
        const {
            pageContent,
            switchEditPage,
            nowPage,
        } = this.props;
        return (
            <Fragment>
                <tbody className="ui-sortable">
                { values(pageContent).map((item, key) =>
                    <TableFromItem
                        key={`TableFromItem-${key}`}
                        index={key}
                        lineNumber={key}
                        editPageObject={{
                            switchEditPage,
                            DELETE,
                            MODIFY,
                        }}
                        item={item}
                        nowPage={nowPage}
                    />)
                }
                </tbody>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, {thisPage}) => ({
    pageContent: state.Template.pageContent[thisPage],
    nowPage: state.Template.nowPage,

});

const mapDispatch = dispatch => bindActionCreators({
    ...Template,
}, dispatch);

export default SortableContainer(connect(mapStateToProps, mapDispatch)(TableFromContainer));
