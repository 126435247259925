import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import * as Template from '../../actions/Template';
import {
    CLOSE,
    NEW,
    DELETE,
    MODIFY
} from '../../reducers/Template';

class TemplateEdit extends Component {
    handleChange = (newValue, actionMeta) => {
        const { updateSelect } = this.props;
        updateSelect(newValue);
    };

    handleSaveEvent = () => {
        const {
            insertTextTemplateSubmit,
            updateTextTemplateSubmit,
            editPage,
            editMessageFrom: {
                id,
                tagList,
                tagIdList,
                positionLevelList,
                content,
            },
        } = this.props;
        let con = -1;
        const tagSelectd = tagIdList.map( item => {
            if (item !== 0) return tagList.filter( listItem => listItem.id === item)[0] ;
            else {
                con += 1;
                return tagList.filter( listItem => listItem.id === item)[con];
            }
        });
        // console.log(editPage);
        if(editPage === NEW) {
            insertTextTemplateSubmit({
                positionLevelList,
                content,
                tagList: tagSelectd,
            });
        } else if (editPage === MODIFY) {
            updateTextTemplateSubmit({
                id,
                positionLevelList,
                content,
                tagList: tagSelectd,
            });
        }

    };

    handleCheckBoxChange = e => {
        const {
            updateCheckBox
        } = this.props;
        updateCheckBox(Number(e.currentTarget.id));
    };

    handleContentChange = e => {
        const {
            updateContent
        } = this.props;
        updateContent(e.currentTarget.value);
    }

    render() {
        const {
            switchEditPage,
            editPage,
            editMessageFrom: {
                positionLevelList,
                tagList,
                tagIdList,
                content,
            },
        } = this.props;
        const selectOptions = tagList.map(item => ({ label: item.tagName, value: item.id }));
        return (
            <Fragment>
                <div className="form-horizontal" style={{marginTop: '5vh', paddingLeft: '20vw', height: '100vh'}}>
                    <div className="form-group" style={{ margin: '0 0 20px 0'}}>
                        <div style={{fontSize: 20, marginBottom: '5px'}}>
                            可用职等
                        </div>
                        <div>
                            {positionLevelList.map(item => (
                                <Fragment>
                                    <input
                                        type="checkbox"
                                        id={item.id}
                                        name={item.name}
                                        checked={item.status}
                                        disabled={MODIFY === editPage}
                                        onChange={this.handleCheckBoxChange}
                                    />
                                    <label
                                        style={{fontSize: 16, fontWeight: 'normal', margin: '0 10px'}}
                                        for={item.id}
                                    >
                                    {item.name}
                                    </label>
                                </Fragment>
                            ))}
                        </div>
                    </div>

                    <div className="form-group" style={{ margin: '0 0 20px 0'}}>
                        <div
                            style={{fontSize: 20, marginBottom: '5px'}}
                            for="Content">
                            内容
                        </div>
                        <div className="col-md-6">
                            <input
                                className="form-control text-box single-line"
                                id="Content"
                                name="Content"
                                type="text"
                                onChange={this.handleContentChange}
                                value={content} />
                            <span
                                className="field-validation-valid text-danger"
                                data-valmsg-for="Content"
                                data-valmsg-replace="true">
                            </span>
                        </div>
                    </div>

                    <div className="form-group" style={{ margin: '0 0 20px 0'}}>
                        <div
                            style={{fontSize: 20, marginBottom: '5px'}}
                            for="CSPositionLevelId">
                            标签类型(每个标签请用回车键或点击Create选单分开)
                        </div>
                        <Creatable
                            defaultValue={
                                tagIdList.map(item => selectOptions.filter(
                                        select => select.value === item
                                    )[0]
                                )
                            }
                            isMulti
                            name="Tag"
                            options={selectOptions}
                            onChange={this.handleChange}
                            className="col-md-7"
                            classNamePrefix="select"
                        />
                    </div>
                    <div className="form-group" style={{ margin: '0 0 20px 0'}}>
                        <input
                            type="submit"
                            value="储存"
                            onClick={this.handleSaveEvent}
                            className="btn btn-info" />
                        <div
                            style={{marginLeft: '10px'}}
                            className="btn btn-default"
                            onClick={() => switchEditPage(CLOSE, 0)}
                        >
                        取消
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => ({
    editPage: state.Template.editPage,
    editMessageFrom: state.Template.editMessageFrom,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...Template,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TemplateEdit);
