import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import * as CSVactions from '../../actions/CSV';

const styles = {
    wrapper: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '80px',
    },
    line: {
        display: 'flex',
    },
    input: {
        width: '700px',
        height: '45px',
        border: '1px solid rgb(153, 153, 153)',
        borderRadius: '5px',
        fontSize: '20px',
    },
    p: {
        border: '0px',
        fontSize: '20px',
        fontWeight: 'bold',
        margin: '10px',
    },
    padding15: {
        padding: '15px',
    }
};

class CSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm'),
            endDate: moment().format('YYYY-MM-DDTHH:mm'),
        };
    }

    componentDidMount() {
        const {
            getCSV,
        } = this.props;
        const {
            startDate,
            endDate,
        } = this.state;
        getCSV({
            startDT: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDT: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            getCSV,
        } = this.props;
        const {
            startDate,
            endDate,
        } = this.state;
        if(startDate !== nextState.startDate || endDate !== nextState.endDate ) {
            getCSV({
                startDT: moment(nextState.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                endDT: moment(nextState.endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            });
            return false;
        }
        return true;
    }


    handleSubmit = event => {
        const {
            getCSV,
        } = this.props;
        const {
            startDate,
            endDate,
        } = this.state;
        getCSV({
            startDT: moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDT: moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        });
        event.preventDefault();
    }

    render() {
        const {
            startDate,
            endDate,
        } = this.state;
        const {
            CSVdata,
        } = this.props;
        return (
            <div style={styles.wrapper} >
                <div
                    style={styles.line}
                >
                    <p style={styles.p}>
                    开始时间：
                    </p>
                    <input
                        style={styles.input}
                        type="datetime-local"
                        value={startDate}
                        onChange={
                            event =>
                            this.setState({ startDate: event.target.value })
                        }
                    />
                </div>
                <div
                    style={styles.line}
                >
                    <p style={styles.p}>
                    结束时间：
                    </p>
                    <input
                        style={styles.input}
                        type="datetime-local"
                        value={endDate}
                        onChange={
                            event =>
                            this.setState({ endDate: event.target.value })
                        }
                    />
                </div>
                <CSVLink
                    className="btn btn-info"
                    style={styles.p}
                    data={CSVdata}>
                    点我下载
                </CSVLink>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    CSVdata: state.CSV.CSVdata,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ...CSVactions,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CSV);
