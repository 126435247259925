import {
    GET_SESSIONEZAGENT_SUCCESS,
    CHANGE_SEARCHSTRING2,
    CHANGE_SEARCHKEYWORD_STRING,
    GET_MESSAGE_SUCCESS,
    GET_MORE_MESSAGE_SUCCESS,
    GET_MEMBER_DATA_SUCCESS,
    CHANGE_INPUT_PAGE,
    INITIAL_DATA,
    INITIAL_STATUS,
} from '../actions/Ezagent';
import {
    SENDMESSAGE,
    UPDATETOREAD,
} from '../actions/SocketAction';

const initMessageStatus = {
    StatusGetMessage: false,
    StatusMoreMessage: false,
    StatusSocketMessage: false,
    StatusKeyWordMessage: false,
};

const initState = {
    SearchResult: [],
    SearchData: {
        SearchString: '',
        SearchKeyWordString: '',
    },
    Pagination: {
        NowPage: 1,
        TotalPage: 1,
        InputPage: 1,
    },
    Content: [],
    MessageID: {
        MoreMessageID: 0,
        PreMoreMessageID: 0,
        KeywordMessageID: 0,
    },
    MessageStatus: {
        ...initMessageStatus,
    },
    MoreMessageID: 0,
    PreMoreMessageID: 0,
    KeywordMessageID: 0,
    MemberAccount: {},
    StatusMoreMessage: false,
    MemberInfoBar: '',
};

export default (state = initState, action) => {
    switch (action.type) {
        case CHANGE_INPUT_PAGE:
            return {
                ...state,
                Pagination: {
                    ...state.Pagination,
                    InputPage: action.InputPage,
                },
            };
            //OK
        case GET_SESSIONEZAGENT_SUCCESS: {
            if (action.data.data.sessionHistory.length === 0 ) {
                return {
                    ...state,
                    SearchResult: [],
                    Pagination: {
                        NowPage: action.data.data.pageInfo.nowPage,
                        TotalPage: action.data.data.pageInfo.totalPage,
                        InputPage: action.data.data.pageInfo.nowPage,
                    }
                };
            }
            if (action.data.data.pageInfo.nowPage < 1) return state;
            if (action.data.data.pageInfo.nowPage > action.data.data.pageInfo.totalPage) return state;
            return {
                ...state,
                SearchResult: [
                    ...action.data.data.sessionHistory,
                ],
                Pagination: {
                    NowPage: action.data.data.pageInfo.nowPage,
                    TotalPage: action.data.data.pageInfo.totalPage,
                    InputPage: action.data.data.pageInfo.nowPage,
                }
            };

        }

        case CHANGE_SEARCHSTRING2:
            return {
                ...state,
                SearchData: {
                    ...state.SearchData,
                    SearchString: action.data,
                },
            };
        case CHANGE_SEARCHKEYWORD_STRING:
            return {
                ...state,
                SearchData: {
                    ...state.SearchData,
                    SearchKeyWordString: action.data,
                },
            };
        case GET_MESSAGE_SUCCESS:
            return {
                ...state,
                Content: [
                    ...action.data.data.messageList,
                ],
                MessageID: {
                    MoreMessageID: action.data.data.moreMessageID,
                    PreMoreMessageID: action.data.data.moreMessageID,
                    KeywordMessageID: action.data.data.keywordMessageID,
                },
                Member: action.props,
                MessageStatus: {
                    ...initMessageStatus,
                    StatusGetMessage: true,
                    StatusKeyWordMessage: action.data.data.keywordMessageID > 0 && true,
                },
            };
        case GET_MORE_MESSAGE_SUCCESS:
            if (action.data.data.messageList.length === 0) 
                return {
                    ...state,
                    MessageID: {
                        ...state.MessageID,
                        MoreMessageID: action.data.data.moreMessageID,
                        PreMoreMessageID: action.data.data.moreMessageID,
                    },
                };
            if (state.MessageID.MoreMessageID === 0)
                return {
                    ...state,
                    Content: [
                        ...action.data.data.messageList,
                        ...state.Content,
                    ],
                    MessageID: {
                        ...state.MessageID,
                        MoreMessageID: action.data.data.moreMessageID,
                        PreMoreMessageID: action.data.data.moreMessageID,
                    },
                    MessageStatus: {
                        ...initMessageStatus,
                        StatusMoreMessage: true,
                    },
                };
                return {
                    ...state,
                    Content: [
                        ...action.data.data.messageList,
                        ...state.Content,
                    ],
                    MessageID: {
                        ...state.MessageID,
                        PreMoreMessageID: state.MessageID.MoreMessageID,
                        MoreMessageID: action.data.data.moreMessageID,
                    },
                    MessageStatus: {
                        ...initMessageStatus,
                        StatusMoreMessage: true,
                    },
                };
        case GET_MEMBER_DATA_SUCCESS:
        return {
            ...state,
            MemberInfoBar: action.data,
        };
        case INITIAL_DATA:
            return {
                ...initState,
            };
        case INITIAL_STATUS:
            return {
                ...state,
                MessageStatus: {
                    ...initMessageStatus,
                },
            };
        case SENDMESSAGE: {
            return {
                ...state,
                SearchResult: state.SearchResult.map(searchResult => (searchResult.sessionID === action.data.sessionID
                    ? {
                      ...searchResult,
                      lastText: action.data.contentType === 1 ?
                      action.data.content :
                      (
                        action.data.contentType === 2 ?
                        action.data.sessionContent :
                        state.SearchResult.find(searchResult => searchResult.sessionID === action.data.sessionID).lastText
                      ),
                      createDt: action.data.createDt,
                      isSessionEnd: action.data.isSessionEnd,
                    }
                    : {
                      ...searchResult,
                    })),
                Content: [
                    ...state.Content,
                    ...action.data.contentType === 2 ? 
                        action.data.content.reduce((prev, curr, key) => {
                            return [...prev, {
                                content: action.data.content,
                                contentType: action.data.contentType,
                                createDt: action.data.createDt,
                                fromAccount: action.data.fromAccount,
                                fromType: action.data.fromType,
                                isRead: action.data.isRead,
                                messageID: action.data.messageID,
                                roomGuid: action.data.roomGuid,
                                sessionID: action.data.sessionID,
                                toAccount: action.data.toAccount,
                                toType: action.data.toType,
                                isSessionEnd: action.data.isSessionEnd,
                                content: curr,
                                createDt:action.data.createDt[key],
                            }];
                        }, [])
                    :[{
                        content: action.data.content,
                        contentType: action.data.contentType,
                        createDt: action.data.createDt,
                        fromAccount: action.data.fromAccount,
                        fromType: action.data.fromType,
                        isRead: action.data.isRead,
                        messageID: action.data.messageID,
                        roomGuid: action.data.roomGuid,
                        sessionID: action.data.sessionID,
                        toAccount: action.data.toAccount,
                        toType: action.data.toType,
                        isSessionEnd: action.data.isSessionEnd,
                    }]
                ],
                MessageStatus: {
                    ...initMessageStatus,
                    StatusSocketMessage: true,
                },
            };
        }
        case UPDATETOREAD: {
            return {
                ...state,
                Content: action.data.fromType === 0 ? state.Content.map(con => (con.sessionID === action.data.sessionID
                    ? {
                      ...con,
                      isRead: 1,
                    }
                    : {
                      ...con,
                    })) : state.Content,
            };
        }
        default:
            return state;
    }
};