import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Chat from '../../actions/Chat';
import notice from '../../static/noticeonline.mp3';

class WaitingSound extends Component {
    // componentDidMount() {
    //     const {
    //         data,
    //         closeSound,
    //     } = this.props;
    //     if(data){
    //     this.timer = setTimeout(
    //         () => { closeSound(); },
    //         1200
    //         );
    //     }
    // }
    // componentWillUnmount() {
    //     this.timer && clearTimeout(this.timer);
    //   }

    render() {
        return (
            <div style={{ display: 'none',}}>
                <audio
                    autoPlay loop
                    id="demo"
                    ref="audio_tag"
                    src={notice} />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    data: state.Chat.noticeSound,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WaitingSound);