import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaginationContainer from './PaginationContainer';
import * as Ezagent from '../../actions/Ezagent';

class Pagination extends Component {
    render() {
        const {
            getSessionEzagent,
            changeInputPage,
            Pagination: {
                NowPage,
                TotalPage,
                InputPage,
            },
            SearchString,
            SearchKeyWordString,
        } = this.props;
        return (
            <Fragment>
                <div>
                    <ul className="pagination" style={{display: 'flex'}}>
                        <li className="paginate_button page-item"
                            onClick={() => getSessionEzagent(SearchString, 1, SearchKeyWordString)}
                            style={{ cursor: 'pointer'}}>
                            <div className="page-link" title="第一页">
                                <i className="fa fa-angle-double-left"></i>
                            </div>
                        </li>
                        <li className="paginate_button page-item"
                            onClick={() => getSessionEzagent(SearchString, NowPage-1, SearchKeyWordString)}
                            style={{ cursor: 'pointer'}}>
                            <div className="page-link" title="上一页">
                                <i className="fa fa-angle-left"></i>
                            </div>
                        </li>
                        <PaginationContainer />
                        <li className="paginate_button page-item"
                            onClick={() => getSessionEzagent(SearchString, NowPage+1, SearchKeyWordString)}
                            style={{ cursor: 'pointer'}}>
                            <div className="page-link" title="下一页">
                                <i className="fa fa-angle-right"></i>
                            </div>
                        </li>
                        <li className="paginate_button page-item"
                            onClick={() => getSessionEzagent(SearchString, TotalPage, SearchKeyWordString)}
                            style={{ cursor: 'pointer'}}>
                            <div className="page-link" title="最末页">
                                <i className="fa fa-angle-double-right"></i>
                            </div>
                        </li>
                        <li className="paginate_button page-item">
                            <input
                                type="number"
                                className="paginateInput"
                                value={InputPage}
                                onChange={e=> {
                                    changeInputPage(e.target.value);
                                    if(e.target.value !== '' && e.target.value <= TotalPage)
                                    getSessionEzagent(SearchString, e.target.value, SearchKeyWordString);
                                }}
                                min="1"
                                max={TotalPage} />
                        </li>
                    </ul>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Pagination: state.Ezagent.Pagination,
    SearchKeyWordString: state.Ezagent.SearchData.SearchKeyWordString,
    SearchString: state.Ezagent.SearchData.SearchString,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Ezagent,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
