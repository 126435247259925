import React, { Component, Fragment } from 'react';

class AnimateLoading extends Component {
    render() {
        return (
            <Fragment>
                <div className="loading text-center">
                    <svg width="36px" height="36px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className="lds-ellipsis" style={{background: 'none'}}>
                        <circle cx="84" cy="50" r="4.33928" fill="#d3e6ea">
                            <animate attributeName="r" values="10;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="0s"></animate>
                            <animate attributeName="cx" values="84;84;84;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="0s"></animate>
                        </circle>
                        <circle cx="69.2465" cy="50" r="10" fill="#a5a6a0">
                            <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="-2s"></animate>
                            <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="-2s"></animate>
                        </circle>
                        <circle cx="35.2465" cy="50" r="10" fill="#cacbc5">
                            <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="-1s"></animate>
                            <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="-1s"></animate>
                        </circle>
                        <circle cx="16" cy="50" r="5.66072" fill="#f3dcb2">
                            <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="0s"></animate>
                            <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="0s"></animate>
                        </circle>
                        <circle cx="16" cy="50" r="0" fill="#d3e6ea">
                            <animate attributeName="r" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="0s"></animate>
                            <animate attributeName="cx" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="4s" repeatCount="indefinite" begin="0s"></animate>
                        </circle>
                    </svg>
                </div>           
            </Fragment>
        );
    }
}

export default AnimateLoading;