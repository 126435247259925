import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionTemplate from '../actions/Template';
import  GroupContainer from './Template/GroupContainer';
import ContentContainer from './Template/ContentContainer';
import {
    CLOSE,
    NEW,
    DELETE,
    MODIFY,
} from '../reducers/Template.js';
import TemplateEdit from './Template/TemplateEdit';

class Template extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        const nextEditPage = nextProps.editPage;
        const {
            editPage,
            getSearchTextTemplate
        } = this.props;
        if (nextEditPage===CLOSE && nextEditPage!==editPage) {
            getSearchTextTemplate();
            return true;
        }
        return true;
    }

    componentDidMount() {
        const { getSearchTextTemplate } = this.props;
        getSearchTextTemplate();
    }

    render() {
        const {
            editPage,
            switchEditPage,
        } = this.props;
        return (
            <Fragment>
               {editPage === CLOSE &&
                    <div className="content-wrapper" style={{overflowY:'scroll'}}>
                        <div className="container-fluid" id="container">
                            <div className="baseTemplate">
                                <div className="row">
                                    <div className="col-md-offset-11">
                                        <button className="btn btn-info" onClick={() => switchEditPage(NEW)}>新增</button>
                                    </div>
                                </div>
                                <div className="tabbable">
                                    <GroupContainer />
                                    <ContentContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                { editPage === NEW && <TemplateEdit /> }
                { editPage === MODIFY && <TemplateEdit />  }
                { editPage === DELETE && <TemplateEdit /> }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    editPage: state.Template.editPage,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...actionTemplate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Template);