import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ControlUserInterface from '../../actions/ControlUserInterface';

const styles = {
    main: {
        position: 'absolute',
        transform: 'translate3d(0px, -364px, 0px)',
        top: '0px',
        left: '0px',
        willChange: 'transform',
    }
};

class WeiXin extends Component {
    iconLocalPath = (element, index) =>
      (<div key={index} className="dropdown-item" onClick={() => this.props.concatTextValue(element.content)}>
        <img src={`images/weixin/${index}.png`} className="weixin" alt="weixin" />
      </div>);

    render() {
        return (
            <div className="dropdown">
                <i className="fa fa-weixin" title="微信表情符号" onClick={this.props.openWeiXin}></i>
                {
                    this.props.showWeiXin &&
                    <div className="dropdown-menu style-2 emoji show" aria-labelledby="weixin" style={styles.main} >
                        {this.props.WeiXinImg.map((element, index) => this.iconLocalPath(element, index))}
                    </div>
                }

            </div>
        );
    }
}
const mapStateToProps = state => ({
    showWeiXin: state.ControlUserInterface.WeiXin,
});

const mapDispatchToProps = dispatch =>  bindActionCreators({
    ...ControlUserInterface,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Radium(WeiXin));