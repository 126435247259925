import {
    OPEN_WEIXIN,
    CLOSE_WEIXIN,
    OPEN_TEMPLATE_MESSAGE,
    CLOSE_TEMPLATE_MESSAGE,
    OPEN_SUBPAGE_TEMPLATE_MESSAGE,
    CLOSE_SUBPAGE_TEMPLATE_MESSAGE,
    OPEN_MODAL,
    CLOSE_MODAL,
} from '../actions/ControlUserInterface';

import {
    GET_TEMPLATE_MESSAGE_SUCCESS,
} from '../actions/Chat';

export default ( state= {
    WeiXin: false,
    Modal: false,
    ModalData: '',
    TemplateMessage: false,
    TemplateMessagePage: [],
}, action) => {
    switch (action.type) {
        case OPEN_WEIXIN:
            return {
                ...state,
                WeiXin: true,
            };
        case CLOSE_WEIXIN:
            return {
                ...state,
                WeiXin: false,
            };
        case OPEN_TEMPLATE_MESSAGE:
            return {
                ...state,
                TemplateMessage: true,
            };
        case CLOSE_TEMPLATE_MESSAGE:
            return {
                ...state,
                TemplateMessage: false,
            };
        case OPEN_SUBPAGE_TEMPLATE_MESSAGE:
            return {
                ...state,
                TemplateMessagePage:[
                    ...state.TemplateMessagePage,
                    state.TemplateMessagePage[`${action.Page}`].show = true,
                ],
            };
        case GET_TEMPLATE_MESSAGE_SUCCESS:
            return {
                ...state,
                TemplateMessagePage: [
                    ...action.data.data.listTemplate.map(() => ({show: false}))
                ],
            };
        case CLOSE_SUBPAGE_TEMPLATE_MESSAGE:
            return {
                ...state,
                TemplateMessagePage: [
                    ...state.TemplateMessagePage.map(() => ({show:false}))
                ],
            };
        case OPEN_MODAL:
            return {
                ...state,
                Modal: true,
                ModalData: action.data,
            };
        case CLOSE_MODAL:
            return {
                ...state,
                Modal: false,
                ModalData: '',
            };
        default:
            return state;
    }
};