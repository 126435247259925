import React, { Component, Fragment } from 'react';
import moment from 'moment';
import Support from '../../images/support.svg';

const getMoreMessageOffsetConstant = 101;
const createMarkup = input => ( {__html: input });
const createMarkupImg = url => ( {__html: `<img src=${url} alt="img" style="height:150px"/>`} );
const SystemItem = content => (
    <ul>
        <li className="clearfix center">
            <div className="chat-body clearfix">
                <p dangerouslySetInnerHTML={createMarkup(content)}></p>
            </div>
        </li>
    </ul>
);

class ChatHistoryItem extends Component {
    constructor(props) {
        super(props);
        this.refLine = React.createRef();
    }

    componentDidMount() {
        const {
            status: {
                StatusMoreMessage,
                PreMoreMessageID,
            },
            messageID,
            scroll,
        } = this.props;
        if (StatusMoreMessage && messageID === PreMoreMessageID)
            scroll(this.refLine.current.offsetTop - getMoreMessageOffsetConstant);

    }

    componentDidUpdate() {
        const {
            status: {
                StatusMoreMessage,
                PreMoreMessageID,
            },
            messageID,
            scroll,
        } = this.props;
        if (StatusMoreMessage && messageID === PreMoreMessageID)
            scroll(this.refLine.current.offsetTop - getMoreMessageOffsetConstant);

    }
    render() {
        const {
            status: {
                SearchKeyWordString,
            },
            createDt,
            contentType,
            content,
            toType,
            isRead,
            fromAccount,
            openModal,
        } = this.props;

        return (
            <Fragment>
                <li className="clearfix" ref={this.refLine}>
                    { contentType === 3 ?
                        SystemItem(content):
                        <ul>
                            <li className="clearfix center showdate">
                                <div className="chat-body clearfix">
                                    <p>{moment.unix(createDt).format('YYYY/MM/DD (dddd)')}</p>
                                </div>
                            </li>
                            { toType === 0 ?
                                <li className="clearfix left">
                                    {(SearchKeyWordString && content.indexOf(SearchKeyWordString) >= 0) && <a className='keywordtag'></a>}
                                    <span className="chat-img">
                                        <small className="text-muted text-name"></small>
                                        <span className="Icon_chat Icon_small">{fromAccount && fromAccount[0]}</span>
                                        <small className="text-muted text-time">{moment.unix(createDt).format('HH:mm')}</small>
                                    </span>
                                    <div className="chat-body clearfix">
                                    { contentType === 2 ?
                                        <p className="hasImg" dangerouslySetInnerHTML={createMarkupImg(content)} onClick={() => openModal(content)}></p>
                                        :
                                        <p dangerouslySetInnerHTML={createMarkup(content)}></p>
                                    }
                                    </div>
                                    { isRead === 1 && <small className="text-muted text-isRead">Read</small> }
                                </li>:
                                <li className="clearfix right">
                                    {(SearchKeyWordString && content.indexOf(SearchKeyWordString) >= 0) && <a className='keywordtag'></a>}
                                    <span className="chat-img">
                                    <small className="text-muted text-name">{fromAccount}</small>
                                    <span className="Icon_chat Icon_small"><img src={Support} alt="Support"/></span>
                                    <small className="text-muted text-time">{moment.unix(createDt).format('HH:mm')}</small>
                                </span>
                                    <div className="chat-body clearfix">
                                    { contentType === 2 ?
                                        <p className="hasImg" dangerouslySetInnerHTML={createMarkupImg(content)} onClick={() => openModal(content)}></p>
                                        :
                                        <p dangerouslySetInnerHTML={createMarkup(content)}></p>
                                    }
                                </div>
                                    { isRead === 1 && <small className="text-muted text-isRead">Read</small> }
                                </li>
                            }
                        </ul>
                    }

                </li>
            </Fragment>
        );
    }
}

export default ChatHistoryItem;