
import {Loading_Show, Loading_Hide} from '../actions/Loading';

const Loading = (state={loading: false},action) => {
    switch(action.type) {
        case Loading_Hide:
            return {loading: false};
        case Loading_Show:
            return {loading: true};
        default:
            return state;
    }
};

export default Loading;