import {
    GET_SEARCHTEXTTEMPLATE_SUCCESS,
    CHANGE_NOW_PAGE,
    SWITCH_EDIT_PAGE,
    INSERT_TEXT_TEMPLATE_SUCCESS,
    UPDATE_TEXT_TEMPLATE_SUCCESS,
    DELETE_TEXT_TEMPLATE_SUCCESS,
    UPDATE_CHECK_BOX,
    UPDATE_CONTENT,
    UPDATE_SELECT,
    INSERT_TEXT_TEMPLATE_SUBMIT_SUCCESS,
    NEW_TEMPLATE_LIST,
} from '../actions/Template';

export const CLOSE = 'TEMPLATE_PAGE/CLOSE';
export const NEW = 'TEMPLATE_PAGE/NEW';
export const DELETE = 'TEMPLATE_PAGE/DELETE';
export const MODIFY = 'TEMPLATE_PAGE/MODIFY';

const initialEditMessageFrom = {
    id: 0,
    content: '',
    positionLevelList: [],
    tagIdList: [],
    tagList: [],
};

const initialState = {
    pageContent: {},
    pageList: [],
    nowPage: '',
    editPage: CLOSE,
    editMessageID: 0,
    editMessageFrom: initialEditMessageFrom,
};

const createTextTemplateObject = entryObjet => {
    let outputObjet = {};
    entryObjet.map(item => outputObjet[`${item.levelName}`] = {
        ...item.textTemplateList,
    });
    return outputObjet;
};

export default (state = initialState, action) => {
    switch(action.type) {
        case GET_SEARCHTEXTTEMPLATE_SUCCESS:
            if (action.data.data.positionLevelPage.map(item => item.levelName).findIndex( item => item === state.nowPage) === -1) state.nowPage = '';
            return {
                ...state,
                pageList: [...action.data.data.positionLevelPage.map(item => item.levelName)],
                pageContent: {
                    ...createTextTemplateObject(action.data.data.positionLevelPage),
                },
                nowPage: state.nowPage === '' ? action.data.data.positionLevelPage[0].levelName : state.nowPage,
            };
        case CHANGE_NOW_PAGE:
            return {
                ...state,
                nowPage: action.data,
            };
        case NEW_TEMPLATE_LIST: {
            return {
                ...state,
                pageContent: {
                    ...state.pageContent,
                    [`${action.data.thisPage}`]:{
                        ...action.data.newPageContent,
                    },
                },
            };
        }

        case SWITCH_EDIT_PAGE:
            return {
                ...state,
                editPage: action.data.newStatus,
                editMessageID: action.data.messageID,
            };
        case INSERT_TEXT_TEMPLATE_SUCCESS:
            return {
                ...state,
                editPage: NEW,
                editMessageFrom: {
                    id: action.data.data.id,
                    content: action.data.data.content,
                    positionLevelList: action.data.data.positionLevelList,
                    tagIdList: action.data.data.tagIdList,
                    tagList: action.data.data.tagList,
                },
            };
        case UPDATE_TEXT_TEMPLATE_SUCCESS:
            return {
                ...state,
                editPage: MODIFY,
                editMessageFrom: {
                    id: action.data.data.id,
                    content: action.data.data.content,
                    positionLevelList: action.data.data.positionLevelList,
                    tagIdList: action.data.data.tagIdList,
                    tagList: action.data.data.tagList,
                },
            };
        case UPDATE_CHECK_BOX:
            return {
                ...state,
                editMessageFrom: {
                    ...state.editMessageFrom,
                    positionLevelList: state.editMessageFrom.positionLevelList.map((item) => {
                        if (item.id === action.ValueID) {
                            return {
                                ...item,
                                status: !item.status,
                            };
                        };
                        return item;
                    }),
                }
            };
        case UPDATE_CONTENT:
            return {
                ...state,
                editMessageFrom: {
                    ...state.editMessageFrom,
                    content: action.stringContent,
                }
            };
        case UPDATE_SELECT:
            return {
                ...state,
                editMessageFrom: {
                    ...state.editMessageFrom,
                    tagIdList:action.selectObject.map(item => {
                        if (typeof item.value === 'string') return 0;
                        return item.value;
                    }),
                    tagList: [
                        ...state.editMessageFrom.tagList.filter(item => !item.__isNew__),
                        ...action.selectObject.filter(item =>
                            item.__isNew__).map(item => ({
                                id: 0,
                                tagName: item.label,
                                __isNew__:true,
                            })
                        ),
                    ],
                }
            };
        case INSERT_TEXT_TEMPLATE_SUBMIT_SUCCESS:
            return {
                ...state,
                editPage: CLOSE,
                editMessageFrom: initialEditMessageFrom,
            };
        case DELETE_TEXT_TEMPLATE_SUCCESS:
            const outputSubPageContent = Object.values(state.pageContent[action.data.nowPage]).filter(item => item.id !== action.data.messageID);
            const outputPageContent = state.pageContent;
            if(outputSubPageContent.length === 0) {
                delete outputPageContent[action.data.nowPage];
                return {
                    ...state,
                    pageContent: outputPageContent,
                    pageList: state.pageList.filter(item => item !== action.data.nowPage),
                    nowPage: state.pageList[0],
                };
            }
            return {
                ...state,
                pageContent: {
                    ...state.pageContent,
                    [`${action.data.nowPage}`]: { ...outputSubPageContent },
                }
            };
        default:
            return state;
    }
};
