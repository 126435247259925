import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ControlUserInterface from '../../actions/ControlUserInterface';
import * as Ezagent from '../../actions/Ezagent';
import { LoadingShow } from '../../actions/Loading';
import ChatHistoryItem from '../../components/Ezagent/ChatHistoryItem';
import TemplateMessage from '../../containers/Chat/TemplateMessage.js';
import WeiXin from '../../containers/Chat/WeiXin.js';
import * as Chat from '../../actions/Chat';
const styles = {
    dropdown: {
        backgroundColor: 'black',
        position: 'absolute',
        bottom: '50px',
        right: '50px',
        zIndex: "200",
        fontSize: '20px',
        color: '#fff',
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        textAlign: 'center',
        cursor: 'pointer',
    },
};
class ChatEzagent extends Component {
    constructor(props) {
        super(props);
        this.containerChatList = React.createRef();
        this.state = {
            ImgID: 0,
            imageValue: [],
            imageObject: [],
            isBottom: false,
        };
        this.imgInput = createRef();
        this.textArea = createRef();
    }
    handleKeyPress = e => {
        const {
            sendMessage,
            uploadImage,
            memberData,
            Member,
            UserAccount
        } = this.props;
        const { imageObject } = this.state;
        const formData = new FormData();
        formData.append('FromType', 0);
        formData.append('ToType', 1);
        formData.append('SessionID', Member.sessionID);
        formData.append('RoomGuid', Member.roomGuid);
        formData.append('FromID', Member.userID);
        formData.append('ToID', Member.memberID);
        formData.append('ChannelType', 1);
        formData.append('ContentType', 2);
        imageObject.map(item => formData.append('Files', item.ImgFiled));
        if (e.key === 'Enter') {
            // console.log('Member',Member);
            // console.log('memberData',memberData);
            // console.log('UserAccount',UserAccount);
            memberData.sessionID=Member.sessionID;
            memberData.roomGuid=Member.roomGuid;
            memberData.userID=UserAccount.UserID;
            memberData.memberID=Member.memberID;

            e.preventDefault();
            if (imageObject.length > 0) uploadImage(formData);
            if (this.textArea.current.innerText.length !== 0) sendMessage(this.textArea.current.innerText, memberData);
            this.textArea.current.innerText = '';
            this.textArea.current.innerHTML = '';
            this.setState({
                ImgID: 0,
                imageObject: [],
            });

        }
    }

    handleKeyUp = e => {
        const { imageObject } = this.state;
        if (e.key === 'Backspace' || e.key === 'Delete') {
            if (imageObject.length > 0) {
                const textAreaArr = [...this.textArea.current.getElementsByTagName('img')];
                const textAreaImgId = textAreaArr.map(value => value.attributes.getNamedItem('imgid') && parseFloat(value.attributes.getNamedItem('imgid').value));
                const newimageObject = imageObject.filter(item => textAreaImgId.indexOf(item.ImgID) >= 0);
                this.setState({
                    imageObject: [...newimageObject],
                });
            }
        }
    }

    handlePaste = e => {
        const { imageValue, imageObject, ImgID } = this.state;
        const pasteData = e.clipboardData.items;
        for (let i = 0; i < pasteData.length; i++) {
            if (pasteData[i].type.indexOf('image') !== -1) {
                this.setState({
                    ImgID: ImgID + 1,
                    imageValue: [
                        ...imageValue,
                        {
                            "ImgURL": window.URL.createObjectURL(pasteData[i].getAsFile()),
                            "ImgID": ImgID
                        }
                    ],
                    imageObject: [
                        ...imageObject,
                        {
                            "ImgFiled": pasteData[i].getAsFile(),
                            "ImgID": ImgID
                        }
                    ]
                });
            }
        }
    }
    inputImgClick = () => this.imgInput.current.click();

    inputImgChange = e => {
        const { memberData, uploadImage ,Member,UserAccount} = this.props;
        memberData.sessionID=Member.sessionID;
        memberData.roomGuid=Member.roomGuid;
        memberData.userID=UserAccount.UserID;
        memberData.memberID=Member.memberID;
        const formData = new FormData();
        const imgFile = this.imgInput.current.files;
        formData.append('FromType', 0);
        formData.append('ToType', 1);
        formData.append('SessionID', memberData.sessionID);
        formData.append('RoomGuid', memberData.roomGuid);
        formData.append('FromID', memberData.userID);
        formData.append('ToID', memberData.memberID);
        formData.append('ChannelType', 1);
        formData.append('ContentType', 2);
        Object.values(imgFile).map( item => formData.append('Files', item));
        e.target.value = null;
        uploadImage(formData);
    }
    //已讀
     handleFocus = () =>
     {
        const { memberData ,Member,UserAccount,updateToRead} = this.props;
        memberData.sessionID=Member.sessionID;
        memberData.roomGuid=Member.roomGuid;
        memberData.userID=UserAccount.UserID;
        memberData.memberID=Member.memberID;
        console.log("this.props.memberData",this.props.memberData)
        this.props.updateToRead(this.props.memberData);
     }

    scroll = data => {
        this.containerChatList.current.scroll(0, data);
        this.isBottom();
        this.props.initialStatus();
    }

    scrollTop = () =>
        this.containerChatList.current.scroll(0, 0);

    scrollBottom = () =>
        this.containerChatList.current.scroll(0, this.containerChatList.current.scrollHeight);

    handleGetMoreMessage = () => {
        const {
            getMoreMessage,
            MoreMessageID,
            Member,
        } = this.props;
        getMoreMessage({
            sessionID: Member.sessionID,
            roomGuid: Member.roomGuid,
            moreMessageID: MoreMessageID,
        });
        this.props.LoadingShow();
    };
    componentDidMount() {
        const {
            MessageStatus: {
                StatusKeyWordMessage,
                StatusGetMessage,
            },
            Content,
            initialStatus,
        } = this.props;
        if (StatusKeyWordMessage && Content.length > 0) {
            this.scrollTop();
            this.isBottom();
            initialStatus();
        }
        else if (StatusGetMessage && Content.length > 0) {
            this.scrollBottom();
            this.isBottom();
            initialStatus();
        }
    }
    componentDidUpdate() {
        const {
            MessageStatus: {
                StatusKeyWordMessage,
                StatusGetMessage,
                StatusSocketMessage,
            },
            Content,
            //initialStatus,
        } = this.props;
        if (StatusKeyWordMessage && Content.length > 0) {
            // this.scrollTop();
            // this.isBottom();
            // initialStatus();
        }
        else if (StatusGetMessage && Content.length > 0) {
            // this.scrollBottom();
            // this.isBottom();
            // initialStatus();
        }
        else if (StatusSocketMessage) {
 
        }
    }
    toScroll = data => {
        this.containerChatList.current.scroll(0, data);
    }
    isBottom = () => {
        if (this.containerChatList.current === null) return this.setState({ isBottom: false });
        if (this.containerChatList.current.scrollTop === this.containerChatList.current.scrollHeight - this.containerChatList.current.clientHeight)
            this.setState({ isBottom: true });
        else
            this.setState({ isBottom: false });
    }
    bottomIcon = () => (
        <div
            style={styles.dropdown}
            onClick={this.scrollBottom}>
            <i className="fa fa-caret-down" />
        </div>
    );

    render() {
        const {
            WeiXinImg,
            Content,
            openModal,
            Member,
            getMessage2,
            StatusMoreMessage,
            MoreMessageID,
            PreMoreMessageID,
            SearchKeyWordString,
        } = this.props;
        const {
            imageValue,
        } = this.state;
        if (Content.length === 0 && Member === undefined) return null;
        return (
            <Fragment>
                <div className="col-md-9 Chat-Content-Body bk-blue" style={{ display: 'flex' }}>
                    <div className="text-chat-title">
                        {Member.memberName} <small className="text-muted">{Member.memberAccount}</small>
                        <i className="fa fa-refresh" title="重新整理" onClick={() => getMessage2(Member)}></i>
                        <br />
                    </div>
                    {Content.length > 0 &&
                        <ul className="chat style-2" ref={this.containerChatList} onScroll={this.isBottom}>
                            <li>
                                {MoreMessageID !== 0 ?
                                    <button
                                        id="BtnGetMoreMessage"
                                        onClick={this.handleGetMoreMessage}
                                        className="btn">
                                        载入更多
                                    </button> : <div id="NotMoreMessage">没有更多讯息</div>
                                }
                            </li>
                            {!this.state.isBottom && this.bottomIcon()}
                            {Content.map((item, index) => {
                                if (item.sessionID === Member.sessionID && item.roomGuid === Member.roomGuid) {
                                    return (
                                        <ChatHistoryItem
                                            key={index}
                                            openModal={openModal}
                                            scroll={this.scroll}
                                            status={{
                                                StatusMoreMessage,
                                                MoreMessageID,
                                                PreMoreMessageID,
                                                SearchKeyWordString,
                                            }}
                                            {...item} />
                                    );
                                }
                                return null;
                            }
                            )}
                            <li>
                                <div className="Content-Message" style={{ display: 'block' }}>
                                    <div className="Content-Tool">
                                        <div className="pull-left">
                                            <TemplateMessage concatTextValue={this.concatTextValue} />

                                            {/* <WeiXin
                                                WeiXinImg={WeiXinImg}
                                                concatTextValue={this.concatTextValue}
                                            /> */}
                                            <i
                                                className="fa fa-upload"
                                                title="上傳圖片"
                                                id="btn-upload"
                                                onClick={this.inputImgClick}
                                            />
                                            <input
                                                type="file"
                                                id="inputImg"
                                                name="inputImg"
                                                multiple
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                ref={this.imgInput}
                                                onChange={this.inputImgChange}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        id="message"
                                        onKeyPress={this.handleKeyPress}
                                        onKeyUp={this.handleKeyUp}
                                        onFocus={this.handleFocus}
                                        onPaste={this.handlePaste}
                                        ref={this.textArea}
                                        contentEditable="plaintext-only"
                                        suppressContentEditableWarning={true}
                                        style={{ resize: 'none', fontSize: '20px' }}>
                                        {imageValue.map(
                                            (item, index) =>
                                                <img key={index} src={item.ImgURL} imgid={item.ImgID} alt='textImage' />
                                        )}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    }


                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    WeiXinImg: state.Chat.WeiXinImg,
    Content: state.Ezagent.Content,
    MoreMessageID: state.Ezagent.MessageID.MoreMessageID,
    PreMoreMessageID: state.Ezagent.MessageID.PreMoreMessageID,
    Member: state.Ezagent.Member,
    MessageStatus: state.Ezagent.MessageStatus,
    StatusMoreMessage: state.Ezagent.MessageStatus.StatusMoreMessage,
    SearchKeyWordString: state.Ezagent.SearchData.SearchKeyWordString,
    memberData: state.Chat.ChatList.find(el => el.showTitle === true) === undefined ? {} : state.Chat.ChatList.find(el => el.showTitle === true),
    UserAccount: state.Account,
    // StatusGetMessage: state.Ezagent.MessageStatus.StatusGetMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...ControlUserInterface,
    ...Ezagent,
    ...Chat,
    LoadingShow,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatEzagent);