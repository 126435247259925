import React, { Component, Fragment } from 'react';

class GroupItem extends Component {
    render() {
        const {
            data: {
                item,
                nowPage,
                changeNowPage,
            }
        } = this.props;
        return (
            <Fragment>
                { item === nowPage ?
                    <li className="active">
                        <a>{item}</a>
                    </li>
                :
                    <li
                        style={{cursor: 'pointer', color: '#007bff'}}
                        onClick={()=> changeNowPage(item)}>
                        <a>{item}</a>
                    </li>
                }
            </Fragment>
        );
    }
}

export default GroupItem;