import Axios from 'axios';
import { API_CS_HOST } from '../static/config.js';
export const GET_CSACCOUNT_SUCCESS = 'RANGE/GET_CSACCOUNT_SUCCESS';
export const GET_CSACCOUNT_FAIL = 'RANGE/GET_CSACCOUNT_FAIL';
export const UPDATE_CSDATA = 'RANGE/UPDATE_CSDATA';
export const GET_REPORT_SUCCESS = 'RANGE/GET_REPORT_SUCCESS';
export const GET_REPORT_FAIL = 'RANGE/GET_REPORT_FAIL';
export const INIT_TABLEDATA = 'RANGE/INIT_TABLEDATA';

const urlAPI = `${API_CS_HOST}`;
const urlGetCSAccount = '/Report/GetCSAccount';
const urlGetReport = '/Report/GetReport';

const getCSAccountSuccess = data => ({
    type: GET_CSACCOUNT_SUCCESS,
    data,
});

const getCSAccountFail = error => ({
    type: GET_CSACCOUNT_FAIL,
    error,
});

const getReportSuccess = data => ({
    type: GET_REPORT_SUCCESS,
    data,
});

const getReportFail = error => ({
    type: GET_REPORT_FAIL,
    error,
});

export function initTableData() {
    return { type: INIT_TABLEDATA };
};

export function updateCSData(data) {
    return { type: UPDATE_CSDATA, newArray: data, };
};

export const getCSAccount = (data) => {
    return dispatch => {
        Axios({
            url: urlAPI+urlGetCSAccount,
            method: 'POST',
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => {
            dispatch(getCSAccountSuccess(res.data));
        })
        .catch(error => {
            dispatch(getCSAccountFail(error));
        });
    };
};

export const getReport = (data) => {
    return dispatch => {
        Axios({
            url: urlAPI+urlGetReport,
            method: 'POST',
            data: data,
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => {
            dispatch(getReportSuccess(res.data));
        })
        .catch(error => {
            dispatch(getReportFail(error));
        });
    };
};

