import {
    GET_CSACCOUNT_SUCCESS,
    UPDATE_CSDATA,
    GET_REPORT_SUCCESS,
    INIT_TABLEDATA,
} from '../actions/Range.js';

const initialState = {
    statusCode: null,
    message: '',
    CSData: [],
    selectCSData: [],
    reportData: [],
};

const initTableData = (state, action) => {
    const setData = {
      ...state,
      reportData: [],
    };
    return setData;
};

const getCSAccount = (state, action) => {
    const setData = {
      ...state,
      CSData: action.data.data,
    };
    return setData;
};

const updateCSData = (state, action) => {
    const setData = {
      ...state,
      CSData: [
          ...state.CSData.filter(item => !item.__isNew__),
          ...action.newArray.filter(item =>
            item.__isNew__).map(item => ({
                id: 0,
                account: item.label,
                __isNew__:true,
            })
        ),
      ],
      selectCSData: action.newArray,
    };
    return setData;
};

const getReport = (state, action) => {
    const setData = {
      ...state,
      reportData: action.data.data.reportInfoList,
    };
    return setData;
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CSACCOUNT_SUCCESS:
        return getCSAccount(state, action);
      case UPDATE_CSDATA:
        return updateCSData(state, action);
      case GET_REPORT_SUCCESS:
        return getReport(state, action);
      case INIT_TABLEDATA:
        return initTableData(state, action);
      default:
        return state;
    }
  };
  
  export default reducer;