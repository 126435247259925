import React, { Component, Fragment } from 'react';

class TableFromTitle extends Component {
    render() {
        return (
            <Fragment>
                <thead>
                    <tr>
                        <th>
                            No
                        </th>
                        <th>
                            快速回应内容
                        </th>
                        <th>
                            标签类型
                        </th>
                        <th>
                            功能
                        </th>
                        <th>
                            排序
                        </th>
                    </tr>
                </thead>
            </Fragment>
        );
    }
}

export default TableFromTitle;