import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChatContent from './ChatContent.js';
import WeiXin from './WeiXin.js';
import TemplateMessage from './TemplateMessage.js';
import * as Chat from '../../actions/Chat';
import * as ControlUserInterface from '../../actions/ControlUserInterface';
window.URL = window.URL || window.webkitURL;

class ChatRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ImgID:0,
            imageValue: [],
            imageObject: []
        };
        this.imgInput = createRef();
        this.textArea = createRef();
    }

    componentDidMount() {
        this.props.weiXinTemplate();
        this.props.getTemplate();
    }

    handleKeyPress = e => {
        const {
            sendMessage,
            uploadImage,
            memberData
        } = this.props;
        const { imageObject } = this.state;
        const formData = new FormData();
        formData.append('FromType', 0);
        formData.append('ToType', 1);
        formData.append('SessionID', memberData.sessionID);
        formData.append('RoomGuid', memberData.roomGuid);
        formData.append('FromID', memberData.userID);
        formData.append('ToID', memberData.memberID);
        formData.append('ChannelType', 1);
        formData.append('ContentType', 2);
        imageObject.map( item => formData.append('Files', item.ImgFiled));
        if(e.key === 'Enter') {
            e.preventDefault();
            if(imageObject.length > 0) uploadImage(formData);
            if(this.textArea.current.innerText.length !== 0) sendMessage(this.textArea.current.innerText, memberData);
            this.textArea.current.innerText = '';
            this.textArea.current.innerHTML = '';
            this.setState({
                ImgID:0,
                imageObject: [],
             });

        }
    }

    handleKeyUp = e =>{
        const { imageObject } = this.state;
        if(e.key === 'Backspace' || e.key === 'Delete'){
            if(imageObject.length > 0){
                const textAreaArr = [...this.textArea.current.getElementsByTagName('img')];
                const textAreaImgId = textAreaArr.map(value => value.attributes.getNamedItem('imgid') && parseFloat(value.attributes.getNamedItem('imgid').value));
                const newimageObject = imageObject.filter( item => textAreaImgId.indexOf(item.ImgID) >= 0);
                this.setState({
                    imageObject: [...newimageObject],
                });
            }
        }
    }

    handlePaste = e => {
        const { imageValue, imageObject,ImgID } = this.state;
        const pasteData = e.clipboardData.items;
        for (let i = 0 ; i < pasteData.length ; i++) {
            if (pasteData[i].type.indexOf('image') !== -1) {
                this.setState({
                    ImgID: ImgID+1,
                    imageValue: [
                        ...imageValue,
                        {
                            "ImgURL":window.URL.createObjectURL(pasteData[i].getAsFile()),
                            "ImgID":ImgID
                        }
                    ],
                    imageObject: [
                        ...imageObject,
                        {
                            "ImgFiled":pasteData[i].getAsFile(),
                            "ImgID":ImgID
                        }
                    ]
                });
            }
        }
    }

    handleFocus = () => this.props.updateToRead(this.props.memberData);

    inputImgClick = () => this.imgInput.current.click();

    inputImgChange = e => {
        const { memberData, uploadImage } = this.props;
        const formData = new FormData();
        const imgFile = this.imgInput.current.files;
        formData.append('FromType', 0);
        formData.append('ToType', 1);
        formData.append('SessionID', memberData.sessionID);
        formData.append('RoomGuid', memberData.roomGuid);
        formData.append('FromID', memberData.userID);
        formData.append('ToID', memberData.memberID);
        formData.append('ChannelType', 1);
        formData.append('ContentType', 2);
        Object.values(imgFile).map( item => formData.append('Files', item));
        e.target.value = null;
        uploadImage(formData);
    }

    concatTextValue = input => {
        this.props.closeTemplateMessage();
        this.textArea.current.innerText = this.textArea.current.innerText + input;
        this.textArea.current.focus();
    };

    render() {
        const {
            memberData,
            WeiXinImg,
        } = this.props;
        const {
            imageValue,
        } = this.state;
        return (
            <div className="col-md-9 Chat-Content-Body bk-blue" style={{display: 'flex'}}>
                <div className="text-chat-title">
                    {memberData === undefined? null: memberData.memberName} <small className="text-muted">{memberData.memberAccount}</small>
                    <br />
                </div>

                <ChatContent />
                <div className="Content-Message" style={{display: 'block'}}>
                    <div className="Content-Tool">
                        <div className="pull-left">
                            <TemplateMessage concatTextValue={this.concatTextValue} />

                            <WeiXin
                                WeiXinImg={WeiXinImg}
                                concatTextValue={this.concatTextValue}
                            />
                            <i
                                className="fa fa-upload"
                                title="上傳圖片"
                                id="btn-upload"
                                onClick={this.inputImgClick}
                            />
                            <input
                                type="file"
                                id="inputImg"
                                name="inputImg"
                                multiple
                                style={{display: 'none'}}
                                accept="image/*"
                                ref={this.imgInput}
                                onChange={this.inputImgChange}
                            />
                        </div>
                    </div>
                    <div
                        id="message"
                        onKeyPress={this.handleKeyPress}
                        onKeyUp={this.handleKeyUp}
                        onFocus={this.handleFocus}
                        onPaste={this.handlePaste}
                        ref={this.textArea}
                        contentEditable="plaintext-only"
                        suppressContentEditableWarning={true}
                        style={{resize: 'none', fontSize: '20px'}}>
                    {imageValue.map(
                        (item, index) =>
                            <img key={index} src={item.ImgURL} imgid={item.ImgID} alt='textImage' />
                    )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    WeiXinImg: state.Chat.WeiXinImg,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
    ...ControlUserInterface,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);