import React, { Component } from 'react';
import {
    Field,
    reduxForm,
    formValueSelector,
  } from 'redux-form';
  import {
    compose,
    bindActionCreators,
  } from 'redux';
import { connect } from 'react-redux';
import * as MemberVerificationAction from '../actions/MemberVerification.js';

const styles = {
    wrapper: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '80px 0',
    },
    inputOutter:{
        position: 'relative',
        marginTop: '15px',
    },
    text: {
        fontSize: '20px',
        fontWeight: 'bold',
        margin: '10px',
    },
    inputAccount: {
        width: '400px',
        height: '50px',
        border: '1px solid #999',
        borderRadius: '5px',
        fontSize: '20px',
    },
    inputInner: {
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    btnPos: {
        position: 'absolute',
        right: '-10px',
        bottom: '0',
        transform: 'translateX(100%)',
    },
    button: {
        width: '100px',
        height: '50px',
        color: '#fff',
        backgroundColor: '#17a2b8',
        borderColor: '#17a2b8',
        borderRadius: '5px',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    btnSent: {
        marginTop: '20px',
    },
};
class MemberVerification extends Component {


    sendAuth() {
        const {
            sendSMSCode,
            Account,
        } = this.props;
        if (Account !== undefined) {
            const postData = {
                code: Account,
            };
            sendSMSCode(postData);
        } else {
            alert('請輸入會員帳號');
        }

    }

    getAuth() {
        const {
            getAuthSMSCode,
            Account,
            AuthCode,
        } = this.props;
        if (Account !== undefined && AuthCode !== undefined ) {
            const postData = {
                code: Account,
                smsCode: AuthCode,
            };
            getAuthSMSCode(postData);
        } else {
            alert('請輸入完整資料');
        }
    }
    
    render() {
        return (
            <div style={styles.wrapper}>
                <div style={styles.inputOutter}>
                    <p style={styles.text}>
                    会员帐号：
                    </p>
                    <Field
                        style={styles.inputAccount}
                        name="Account"
                        component="input"
                        type="text"
                        placeholder="&nbsp;&nbsp;请输入会员帐号" />
                    <div style={styles.btnPos}>
                        <button
                            type="button"
                            style={styles.button}
                            onClick={() => { this.sendAuth(); }}>
                            发送验证码
                        </button>
                    </div>
                </div>
                <div>
                    <p style={styles.text}>
                    验证码：
                    </p>
                    <Field
                        style={styles.inputAccount}
                        name="AuthCode"
                        component="input"
                        type="text"
                        placeholder="&nbsp;&nbsp;请输入验证码" />
                </div>
                <div style={styles.btnSent}>
                    <button
                        type="button"
                        style={styles.button}
                        onClick={() => { this.getAuth(); }}>
                        验证
                    </button>
                </div>
            </div>

        );
    }
}

const selector = formValueSelector('MEMBER_VERIFICATION_FORM');

const formHook = reduxForm({
  form: 'MEMBER_VERIFICATION_FORM',
});

const reduxHook = connect(
  state => ({
    Account: selector(state, 'Account'),
    AuthCode: selector(state, 'AuthCode'),
  }),
  dispatch => bindActionCreators({
    ...MemberVerificationAction,
  }, dispatch),
);

export default compose(
  reduxHook,
  formHook,
)(MemberVerification);
