import Axios from 'axios';
import { values } from 'lodash';
import {
    NEW,
    DELETE,
    MODIFY,
} from '../reducers/Template';
import { handleNetworkError } from './ErrorHandle';
import { API_CS_HOST } from '../static/config';
import arrayMove from 'array-move';
export const GET_SEARCHTEXTTEMPLATE_SUCCESS = 'TEMPLATE/GET_SEARCHTEXTTEMPLATE_SUCCESS';
export const GET_SEARCHTEXTTEMPLATE_FAIL = 'TEMPLATE/GET_SEARCHTEXTTEMPLATE_FAIL';
export const CHANGE_NOW_PAGE = 'TEMPLATE/CHANGE_NOW_PAGE';
export const SWITCH_EDIT_PAGE = 'TEMPLATE/SWITCH_EDIT_PAGE';
export const INSERT_TEXT_TEMPLATE_SUCCESS = 'TEMPLATE/INSERT_TEXT_TEMPLATE_SUCCESS';
export const INSERT_TEXT_TEMPLATE_FAIL = 'TEMPLATE/INSERT_TEXT_TEMPLATE_FAIL';
export const UPDATE_TEXT_TEMPLATE_SUCCESS = 'TEMPLATE/UPDATE_TEXT_TEMPLATE_SUCCESS';
export const UPDATE_TEXT_TEMPLATE_FAIL = 'TEMPLATE/UPDATE_TEXT_TEMPLATE_FAIL';
export const DELETE_TEXT_TEMPLATE_SUCCESS = 'TEMPLATE/DELETE_TEXT_TEMPLATE_SUCCESS';
export const DELETE_TEXT_TEMPLATE_FAIL = 'TEMPLATE/DELETE_TEXT_TEMPLATE_FAIL';
export const UPDATE_CHECK_BOX = 'TEMPLATE/UPDATE_CHECK_BOX';
export const UPDATE_CONTENT = 'TEMPLATE/UPDATE_CONTENT';
export const UPDATE_SELECT = 'TEMPLATE/UPDATE_SELECT';
export const INSERT_TEXT_TEMPLATE_SUBMIT_SUCCESS = 'TEMPLATE/INSERT_TEXT_TEMPLATE_SUBMIT_SUCCESS';
export const INSERT_TEXT_TEMPLATE_SUBMIT_FAIL = 'TEMPLATE/INSERT_TEXT_TEMPLATE_SUBMIT_FAIL';
export const NEW_TEMPLATE_LIST = 'TEMPLATE/NEW_TEMPLATE_LIST';

const urlAPI = `${API_CS_HOST}`;
const urlSearchTextTemplate = '/TextTemplate/SearchTextTemplate';
const urlInsertTextTemplate = '/TextTemplate/InsertTextTemplate';
const urlInsertTextTemplateSubmit = '/TextTemplate/InsertTextTemplateSubmit';
const urlUpdateTextTemplate = '/TextTemplate/UpdateTextTemplate';
const urlUpdateTextTemplateSubmit = '/TextTemplate/UpdateTextTemplateSubmit';
const urlDeleteTextTemplateSubmit = '/TextTemplate/DeleteTextTemplateSubmit';
const urlSortingTemplate = '/TextTemplate/SortingTemplate';

const getSearchTextTemplateSuccess = data => ({
    type: GET_SEARCHTEXTTEMPLATE_SUCCESS,
    data,
});
const getSearchTextTemplateFail = error => ({
    type: GET_SEARCHTEXTTEMPLATE_FAIL,
    error,
});

export const getSearchTextTemplate = () => {
    return dispatch => {
        Axios({
            url: urlAPI+urlSearchTextTemplate,
            method: 'post',
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => dispatch(getSearchTextTemplateSuccess(res.data)))
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const changeNowPage = data => ({
    type: CHANGE_NOW_PAGE,
    data,
});


export const newTemplateList = data => ({
    type: NEW_TEMPLATE_LIST,
    data,
});

export const switchTemplateList = (oldIndex, newIndex, thisPage, pageContent) => {
    return dispatch => {
        Axios({
            url: urlAPI+urlSortingTemplate,
            method: 'post',
            data: { "id": arrayMove(values(pageContent), oldIndex, newIndex).map(item => item.id) },
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => dispatch(newTemplateList({
            newPageContent: arrayMove(values(pageContent), oldIndex, newIndex),
            thisPage,
            res: res.data,
        })))
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const switchEditPage = (newStatus, messageID, nowPage) => {
    if (newStatus === NEW) return insertTextTemplate();
    else if (newStatus === MODIFY) return updateTextTemplate(messageID);
    else if (newStatus === DELETE) return deleteTextTemplate(messageID, nowPage);
    return {
        type: SWITCH_EDIT_PAGE,
        data: {
            newStatus,
            messageID,
        },
    };
};

const insertTextTemplateSuccess = data => ({
    type: INSERT_TEXT_TEMPLATE_SUCCESS,
    data,
});

const insertTextTemplateFail = error => ({
    type: INSERT_TEXT_TEMPLATE_FAIL,
    error,
});

export const insertTextTemplate = () => {
    return dispatch => {
        Axios({
            url: urlAPI+urlInsertTextTemplate,
            method: 'post',
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => dispatch(insertTextTemplateSuccess(res.data)))
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

const updateTextTemplateSuccess = data => ({
    type: UPDATE_TEXT_TEMPLATE_SUCCESS,
    data,
});

const updateTextTemplateFail = error => ({

});

const updateTextTemplate = messageID => {
    return dispatch => {
        Axios.post(
            `${urlAPI}${urlUpdateTextTemplate}?Id=${messageID}`,
        )
        .then(res => dispatch(updateTextTemplateSuccess(res.data)))
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

const deleteTextTemplateSuccess = (messageID, nowPage) => {
    return {
        type: DELETE_TEXT_TEMPLATE_SUCCESS,
        data: {
            messageID,
            nowPage,
        }
    };
};

const deleteTextTemplateFail = error => ({
    type: DELETE_TEXT_TEMPLATE_FAIL,
    error,
});

const deleteTextTemplate = (messageID, nowPage) => {
    return dispatch => {
        Axios.post(
            `${urlAPI}${urlDeleteTextTemplateSubmit}?Id=${messageID}`,
        )
        .then(res => dispatch(deleteTextTemplateSuccess(messageID, nowPage)))
        .catch(error => dispatch(deleteTextTemplateFail(messageID)));
    };
};

export const updateCheckBox = ValueID => ({
    type: UPDATE_CHECK_BOX,
    ValueID,
});

export const updateContent = stringContent => ({
    type: UPDATE_CONTENT,
    stringContent,
});

export const updateSelect = selectObject => ({
    type: UPDATE_SELECT,
    selectObject,
});

export const insertTextTemplateSubmitSuccess = data => ({
    type: INSERT_TEXT_TEMPLATE_SUBMIT_SUCCESS,
    data,
});

export const insertTextTemplateSubmitFail = error => ({
    type: INSERT_TEXT_TEMPLATE_SUBMIT_FAIL,
    error
});

export const insertTextTemplateSubmit = textObject => {
    return dispatch => {
        Axios({
            url: urlAPI+urlInsertTextTemplateSubmit,
            method: 'post',
            data: {
                id: 0,
                positionLevelList: textObject.positionLevelList,
                content: textObject.content,
                tagList: textObject.tagList,
            },
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => dispatch(insertTextTemplateSubmitSuccess(res.data)))
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const updateTextTemplateSubmit = textObject => {
    return dispatch => {
        Axios({
            url: urlAPI+urlUpdateTextTemplateSubmit,
            method: 'post',
            data: {
                id: textObject.id,
                positionLevelList: textObject.positionLevelList,
                content: textObject.content,
                tagList: textObject.tagList,
            },
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => dispatch(insertTextTemplateSubmitSuccess(res.data)))
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};
