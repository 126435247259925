import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import * as Chat from '../../actions/Chat';
import {LoadingShow} from '../../actions/Loading';

const createMarkup = input => ( {__html: input });

class ChatListItem extends Component {
    handleClick = () => {
        const { getMessage, sessionID, roomGuid, UserAccount, getFeedbackMessage, LoadingShow,memberID } = this.props;
        if (UserAccount !== 'csfeedback') getMessage(sessionID, roomGuid, memberID);
        else getFeedbackMessage(sessionID, roomGuid, memberID);
        LoadingShow();
    }

    render() {
        const {
            memberAccount,
            createDt,
            lastText,
            isSessionEnd,
            isRead,
            memberName,
        } = this.props;
        if (memberName === undefined || !createDt || lastText === '') return null;
        return (
            <div onClick={this.handleClick}>
                <div className="row aChat">
                    <div className="col-md-3">
                        {isSessionEnd === 0 ? (
                            <span className="Icon_chat">{memberName[0]}</span>
                        ) : (
                            <span className="Icon_chat offline">{memberName[0]}</span>
                        )}
                    </div>
                    <div className="col-md-9">
                        <span className="text-name">{memberAccount}</span>
                        <span className="text-time">{moment.unix(createDt).format('MM/DD HH:mm')}</span>
                        <span className="text-content" dangerouslySetInnerHTML={createMarkup(lastText)}></span>
                        {isRead === 0 ?
                            <span className="notRead">未读</span>
                            :null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    UserAccount: state.Account.UserAccount,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
    LoadingShow,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChatListItem);