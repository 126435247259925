import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ControlUserInterface from '../../actions/ControlUserInterface';
import Support from '../../images/support.svg';

const getMoreMessageOffsetConstant = 100;
const createMarkup = input => ( {__html: input });
const createMarkupImg = url => ( {__html: `<img src=${url} alt="img" style="height:150px"/>`} );
const SystemItem = content => (
    <ul>
        <li className="clearfix center">
            <div className="chat-body clearfix">
                <p dangerouslySetInnerHTML={createMarkup(content)}></p>
            </div>
        </li>
    </ul>
);

class ChatContentItem extends Component {
    constructor(props) {
        super(props);
        this.ContentLine = React.createRef();
    }
    
    componentDidMount() {
        const {
            fetchMoreMessage,
            MessageID: {
                prevMoreMessageID,
            },
            toScroll,
            messageID,
        } = this.props;

        if(prevMoreMessageID === messageID && fetchMoreMessage)
            toScroll(this.ContentLine.current.offsetTop - getMoreMessageOffsetConstant );
    }

    componentDidUpdate() {
        const {
            fetchMoreMessage,
            MessageID:{
                prevMoreMessageID,
            },
            toScroll,
            messageID,
        } = this.props;

        if(prevMoreMessageID === messageID && fetchMoreMessage)
            toScroll(this.ContentLine.current.offsetTop - getMoreMessageOffsetConstant);
    }
    render() {
        const {
            contentType,
            content,
            isRead,
            fromAccount,
            toType,
            createDt,
            openModal,
        } = this.props;
        moment.locale('zh-tw');
        if (content === '' || !createDt) return null;
        return (
            <li className="clearfix" ref={this.ContentLine}>
                { contentType === 3 ?
                    SystemItem(content):
                    <ul>
                        <li className="clearfix center showdate">
                            <div className="chat-body clearfix">
                                <p>{moment.unix(createDt).format('YYYY/MM/DD (dddd)')}</p>
                            </div>
                        </li>
                        { toType === 0 ?
                            <li className="clearfix left">
                                <span className="chat-img pull-left">
                                    <small className="text-muted text-name"></small>
                                    <span className="Icon_chat Icon_small">{fromAccount && fromAccount[0]}</span>
                                    <small className="text-muted text-time">{moment.unix(createDt).format('HH:mm')}</small>
                                </span>
                                <div className="chat-body clearfix">
                                { (contentType === 2 || contentType === 92) ?
                                    <p className="hasImg" dangerouslySetInnerHTML={createMarkupImg(content)} onClick={() => openModal(content)}></p>
                                    :
                                    <p dangerouslySetInnerHTML={createMarkup(content)}></p>
                                }
                                </div>
                                { isRead === 1 && <small className="text-muted text-isRead">Read</small> }
                            </li>:
                            <li className="clearfix right">
                                <span className="chat-img">
                                <small className="text-muted text-name">{fromAccount}</small>
                                <span className="Icon_chat Icon_small"><img src={Support} alt="Support"/></span>
                                <small className="text-muted text-time">{moment.unix(createDt).format('HH:mm')}</small>
                            </span>
                                <div className="chat-body clearfix">
                                { contentType === 2 ?
                                    <p className="hasImg" dangerouslySetInnerHTML={createMarkupImg(content)} onClick={() => openModal(content)}></p>
                                    :
                                    <p dangerouslySetInnerHTML={createMarkup(content)}></p>
                                }
                            </div>
                                { isRead === 1 && <small className="text-muted text-isRead">Read</small> }
                            </li>
                        }
                    </ul>
                }

            </li>
        );
    }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...ControlUserInterface,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatContentItem);