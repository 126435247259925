import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as History from '../../actions/History';

class MemberInfoBar extends Component {
    // componentDidMount() {
    //     const {
    //         getMemberData,
    //         Member,
    //         renderComplete,
    //     } = this.props;
    //     if (Member) getMemberData(Member.memberID);
    //     else renderComplete('MemberInfoBar');
    // }

    // shouldComponentUpdate(nextProps) {
    //     const {
    //         getMemberData,
    //         Member,
    //     } = this.props;
    //     if (Member.memberID !== nextProps.Member.memberID) {
    //         getMemberData(nextProps.Member.memberID);
    //         //return false;
    //     }
    //     return true;
    // }
    // componentDidUpdate() {
    //     const {renderComplete} =this.props;
    //     console.log('MemberInfoBar')
    //     renderComplete('MemberInfoBar');
    // }

    render() {
        const {
            MemberInfoBar,
            Member,
        } = this.props;
        return (
            <Fragment>
                <div className="col-md-3 heigh100 text-center Chat-Content-Info bg-dark" style={{display: 'flex', fontSize: '16px'}}>
                    <span className="Icon_chat Icon_big">{Member.memberName && Member.memberName[0]}</span>
                    <h4>{Member.memberName && Member.memberName}</h4>
                    <div className="text-muted text-details style-3">{Member.memberAccount && Member.memberAccount}
                        <br />
                        <div dangerouslySetInnerHTML={{__html: MemberInfoBar}} />
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Member: state.History.Member,
    MemberInfoBar: state.History.MemberInfoBar,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...History,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberInfoBar);