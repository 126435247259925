import React, { Component } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ControlUserInterface from '../../actions/ControlUserInterface';

const styles = {
    modalMain: {
        display: 'block',
        paddingLeft: '15px',
    },
    modalMaxWidth: {
        maxWidth: '845px',
    }
};

class Modal extends Component {
    render() {
        const {
            closeModal,
            imagePath,
        } = this.props;
        return (
            <div>
                {document.onkeydown= (e)=>{
                    e.which === 27 && closeModal();
                }}
                <div className="modal fade show" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style={styles.modalMain}>
                    <div className="modal-dialog modal-lg" role="document" style={styles.modalMaxWidth}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <img src={imagePath} alt="modal_img" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop show"></div>
            </div>

        );
    }
}
const mapStateToProps = state => ({
    imagePath: state.ControlUserInterface.ModalData,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...ControlUserInterface,
}, dispatch);

export default connect( mapStateToProps, mapDispatchToProps )(Radium(Modal));