import {
    FETCH_GET_MESSAGE_SUCCESS,
} from '../actions/Chat';

import {
    DELETECHATLIST,
} from '../actions/SocketAction';

import {
    CLEAR_MEMBER_TOKEN,
} from '../actions/Member';

export default ( state = {
    show: '',
}, action) => {
    switch(action.type) {
        case FETCH_GET_MESSAGE_SUCCESS:
            return {
                ...state,
                show: action.data.data.roomGuid,
            };
        case DELETECHATLIST:
            return {
                ...state,
                show: (action.data.roomGuid === state.show) ? '' : state.show
            };
        case CLEAR_MEMBER_TOKEN:
            return {
                show: '',
            };
        default:
            return state;
    }
};