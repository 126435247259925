import { clearMemberTokenSuccess } from './Member';

export const UNAUTHORIZED = 'ERROR/UNAUTHORIZED';
export const OTHER_ERROR = 'ERROR/OTHER_ERROR';


export const handleNetworkError = (response) => {
    if (response === undefined) {
        return ({
            type: OTHER_ERROR,
        });
    }
    if (response.status === 401 && response.status) {
       clearMemberTokenSuccess();
       setTimeout(() => window.alert('登入已过期，请重新登入') , 100);
       return ({
           type: UNAUTHORIZED,
       });
    }
    return ({
        type: OTHER_ERROR,
    });
};