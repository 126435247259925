import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Chat from '../../actions/Chat';
import { LoadingShow } from '../../actions/Loading';
import ChatContentItem from '../../components/Chat/ChatContentItem.js';

const styles ={
    dropdownBack:{
        alignItems:'center',
        textAlign:'center'
    },
    dropdown:{
        backgroundColor:'black',
        position:'absolute',
        bottom:'250px',
        right:'50px',
        zIndex: "200",
        fontSize:'20px',
        color:'#fff',
        width:'30px',
        height:'30px',
        borderRadius:'15px',
    },
};

class ChatContent extends Component {
    constructor(props) {
        super(props);
        this.ChatList = React.createRef();
        this.state = {
            isBottom: false,
        };
    }

    componentDidMount() {
        this.toBottom();
        this.isBottom();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            Status: {
                getMessage,
            },
            initialStatus,
        } = nextProps;
        if (getMessage) {
            initialStatus();
            return {
                ...prevState,
                isBottom: false,
            };
        }
        return prevState;
    }

    componentDidUpdate() {
        const {
            Status: {
                sendMessage,
                fetchMessage,
            },
            initialStatus,
        } = this.props;
        if(sendMessage || fetchMessage) {
            this.toBottom();
            this.isBottom();
            initialStatus();
        }
    }

    toBottom = () => {
        this.ChatList.current.scroll(0, this.ChatList.current.scrollHeight);
    };

    toScroll = data => {
        this.ChatList.current.scroll(0, data);
    }

    isBottom = () => {
        if(this.ChatList.current === null) return this.setState({isBottom: false});
        if(this.ChatList.current.scrollTop === this.ChatList.current.scrollHeight - this.ChatList.current.clientHeight)
            return this.setState({isBottom: true});
        return this.setState({isBottom: false});
    }

    getMoreMsg = () => (
        <li>
            {this.props.moremessageId !== 0 ?
                <button
                    id="BtnGetMoreMessage"
                    onClick={()=>{this.fetchMoreMessage();}}
                    className="btn"
                >载入更多</button>
                : <div id="NotMoreMessage">没有更多讯息</div>
            }
        </li>
    );

    fetchMoreMessage = () => {
        let id= this.props.data[0].sessionID;
        let roomToken= this.props.data[0].roomGuid;
        let moreMessageID = this.props.moremessageId;
        this.props.getMoreMessage(id,roomToken,moreMessageID);
        this.props.LoadingShow();
    }

    bottomIcon = () => (
        <div style={styles.dropdownBack}>
            <div
                style={styles.dropdown}
                onClick={this.toBottom}>
                <i className="fa fa-caret-down" />
            </div>
        </div>
    );

    render() {
        const {
            data,
            MessageID,
            Status: {
                fetchMoreMessage,
            },
        } = this.props;
        let { isBottom } = this.state;
        return (
            <ul className="chat style-2"
                onScroll={this.isBottom}
                ref={this.ChatList}>
                <div ref={this.messageBox}>
                    {this.getMoreMsg()}
                    {!isBottom && this.bottomIcon()}
                    {data &&
                        data.map((item, index) =>
                            <ChatContentItem
                                key={index}
                                MessageID={MessageID}
                                fetchMoreMessage={fetchMoreMessage}
                                toScroll={this.toScroll}
                                {...item} />
                        )
                    }
                </div>
            </ul>
        );
    }
}

const mapStateToProps = state => ({
    data: state.Chat.ChatContent,
    moremessageId:state.Chat.moreMessageId,
    moreMessagebutton: state.Chat.moreMessagebutton,
    Status: state.Chat.Status,
    MessageID: state.Chat.MessageID,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
    LoadingShow,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatContent);
