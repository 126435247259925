import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Chat from '../../actions/Chat';
import Pagination from './Pagination.jsx';
import ChatListItem from '../../components/Chat/ChatListItem.js';


class ChatList extends Component {

    componentDidMount() {
        const {
            UserAccount,
            getSessionHistory,
            getFeedbackSession,
        } = this.props;
        if (UserAccount !== 'csfeedback') getSessionHistory(1);
        else getFeedbackSession(1);
    }

    render() {
        const { TotalPage } =this.props;
        return (
            <div className="col-md-3  col-lg-3 page-height style-1" style={{minWidth: '350px'}}>
                {TotalPage > 1 && <Pagination />}
                {this.props.data && this.props.data.map(
                    (item, index) =>
                    <ChatListItem  key={index} {...item}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    data: state.Chat.ChatList,
    TotalPage: state.Chat.Pagination.TotalPage,
    UserAccount: state.Account.UserAccount,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChatList);