import Axios from 'axios';
import { API_CS_HOST } from '../static/config';
import { handleNetworkError } from './ErrorHandle';
import {LoadingShow,LoadingHide} from './Loading';
export const GET_SESSIONHISTORY_SUCCESS = 'HISTORY/GET_SESSIONHISTORY_SUCCESS';
export const GET_SESSIONHISTORY_FAIL = 'HISTORY/GET_SESSIONHISTORY_FAIL';
export const CHANGE_SEARCHSTRING = 'HISTORY/CHANGE_SEARCHSTRING';
export const CHANGE_SEARCHKEYWORD_STRING = 'HISTORY/CHANGE_SEARCHKEYWORD_STRING';
export const GET_MESSAGE_SUCCESS = 'HISTORY/GET_MESSAGE_SUCCESS';
export const GET_MESSAGE_FAIL = 'HISTORY/GET_MESSAGE_FAIL';
export const GET_MORE_MESSAGE_SUCCESS = 'HISTORY/GET_MORE_MESSAGE_SUCCESS';
export const GET_MORE_MESSAGE_FAIL = 'HISTORY/GET_MORE_MESSAGE_FAIL';
export const GET_MEMBER_DATA_SUCCESS = 'HISTORY/GET_MEMBER_DATA_SUCCESS';
export const GET_MEMBER_DATA_FAIL = 'HISTORY/GET_MEMBER_DATA_FAIL';
export const CHANGE_INPUT_PAGE = 'HISTORY/CHANGE_INPUT_PAGE';
export const INITIAL_DATA = 'HISTORY/INITIAL_DATA';
export const INITIAL_STATUS = 'HISTORY/INITIAL_STATUS';

const urlAPI = `${API_CS_HOST}`;
const urlGetSessionHistory = '/History/GetSessionHistory';
const urlGetMessage = '/History/GetMessage';
const urlGetMemberData = '/Chat/GetMemberData';
const urlGetMoreMessage = '/History/GetMoreMessage';

Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.accessToken}` || null;

export const changeInputPage = InputPage => ({
    type: CHANGE_INPUT_PAGE,
    InputPage,
});

const getSessionhistorySuccess = data => ({
    type: GET_SESSIONHISTORY_SUCCESS,
    data,
});

const getSessionhistoryFail = error => ({
    type: GET_SESSIONHISTORY_FAIL,
    error,
});

export const getSessionHistory = (searchString, pageNumber, searchKeyWordString) => {
    return dispatch => {
        dispatch(LoadingShow());
        Axios({
            url: urlAPI+urlGetSessionHistory,
            method: 'post',
            data: {
                agCodeOrName: searchString,
                keyword: searchKeyWordString,
                nowPage: pageNumber,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
        .then(res => dispatch(getSessionhistorySuccess(res.data)))
        .catch(error => {
            dispatch(handleNetworkError(error.response));
            dispatch(LoadingHide());
        });
    };
};

export const changeSearchString = data => ({
    type: CHANGE_SEARCHSTRING,
    data,
});

export const changeSearchKeyWordString = data => ({
    type: CHANGE_SEARCHKEYWORD_STRING,
    data,
});

const getMessageSuccess = (data, props) => ({
    type: GET_MESSAGE_SUCCESS,
    data,
    props,
});

const getMessageFail = error => ({
    type: GET_MESSAGE_SUCCESS,
    error,
});

export const getMessage = (props) => {
    return (dispatch,getState) => {
        dispatch(LoadingShow());
        Axios({
            url: urlAPI+urlGetMessage,
            method: 'post',
            data: {
                sessionID: props.sessionID,
                roomGuid: props.roomGuid,
                keyword: props.SearchKeyWordString,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
        .then(res => {
            if(!getState().History.Member || (getState().History.Member.memberID !== props.memberID)){
                dispatch(getMemberData(props.memberID));
                dispatch(getMessageSuccess(res.data, props));
            }
            else{
                dispatch(getMessageSuccess(res.data, props));
                dispatch(LoadingHide());
            }
            goToKeyWordTag();
        })
        .catch(error => {
            dispatch(handleNetworkError(error.response));
            dispatch(LoadingHide());
        });
    };
};

//跳到tag
const goToKeyWordTag = () => {
    setTimeout(() => {
        scrollToAnchor("keywordtag");
    }, 300);
}

const scrollToAnchor = (anchorName) => {
    if (!anchorName) 
        return;
    
    let anchorElements = document.getElementsByClassName(anchorName);

    if (anchorElements.length == 0)
        return;

    let anchorElement = anchorElements[anchorElements.length-1];
    anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
}

const getMoreMessageSuccess = data => ({
    type: GET_MORE_MESSAGE_SUCCESS,
    data,
});

const getMoreMessageFail = error => ({
    type: GET_MORE_MESSAGE_FAIL,
    error,
});

export const getMoreMessage = props => {
    return dispatch => {
        Axios({
            url: urlAPI+urlGetMoreMessage,
            method: 'post',
            data: {
                sessionID: props.sessionID,
                roomGuid: props.roomGuid,
                moreMessageID: props.moreMessageID,
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
        .then(res => {
            dispatch(getMoreMessageSuccess(res.data));
            dispatch(LoadingHide());
        })
        .catch(error => {
            dispatch(getMoreMessageFail(error));
            dispatch(LoadingHide());
        });
    };
};

const getMemberDataSuccess = data => ({
    type: GET_MEMBER_DATA_SUCCESS,
    data,
});

const getMemberDataFail = error => ({
    type: GET_MEMBER_DATA_FAIL,
    error,
});

export const getMemberData = MemberID => {
    return dispatch =>{
        Axios({
            url: urlAPI+urlGetMemberData,
            method: 'post',
            data: {
                accountId: MemberID,
                accountCode: '',
            },
            config: { headers: { 'Content-Type': 'application/json' } },
        })
        .then(res => {
            dispatch(getMemberDataSuccess(res.data));
            dispatch(LoadingHide());
        })
        .catch(error => {
            dispatch(handleNetworkError(error.response));
            dispatch(LoadingHide());
        });
    };
};

export const initialDate = () => ({
    type: INITIAL_DATA,
});

export const initialStatus = () => ({
    type: INITIAL_STATUS,
});
