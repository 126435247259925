import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { Provider } from 'react-redux';
import getStore from './store';
import Login from './containers/Login.js';
import Index from './containers/index.js';

const store = getStore();


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/Login" component={Login}/>
            <Route path="/" render={() => (
              <Index store={store}/>
            )}/>
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
