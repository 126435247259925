import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Switch, withRouter } from 'react-router';
import MainNav from './MainNav';
import Chat from './Chat/Chat';
import MemberVerification from './MemberVerification';
import Template from './Template';
import Range from './Range';
import History from './History';
import Ezagent from './Ezagent';
import Modal from '../components/Modal/Modal';
import FailModal from '../components/Modal/FailModal';
import SocketAction from '../actions/SocketAction';
import { SOCKET_HOST } from '../static/config';
import CSV from '../containers/CSV/index';
import Loading from '../components/Loading/Loading';

class index extends Component {
    constructor(props) {
        super(props);
        const {store} = props;
        this.state = {
            socketAction: new SocketAction({store})
        };
    }
    componentDidMount() {
        const {
            accessToken,
            history,
            UserAccount,
        } = this.props;
        if(accessToken === '') history.replace('/Login');
        if(accessToken !== '' && UserAccount !== 'csfeedback') this.state.socketAction.listen(SOCKET_HOST);
    }

    componentDidUpdate() {
        const {
            accessToken,
            history,
        } = this.props;
        if(accessToken === '') history.replace('/Login');
    }

    componentWillUnmount() {
        const {
            accessToken,
            UserAccount,
        } = this.props;
        const {
            socketAction,
        } = this.state;
        if(accessToken !== '' && UserAccount !== 'csfeedback') socketAction.logout();
    }
    render() {
        const {
            modalState,
        } = this.props;
        return (
            <div className="sidenav-toggled modal-open">
                {modalState && <Modal />}
                <MainNav />
                <FailModal />
                <Switch>
                    <Route exact path="/" component={Chat} />
                    <Route path="/MemberVerification" component={MemberVerification} />
                    <Route path="/Template" component={Template} />
                    <Route path="/Range" component={Range} />
                    <Route path="/History" component={History} />
                    <Route path="/Ezagent" component={Ezagent} />
                    <Route path="/CSV" component={CSV} />
                </Switch>
                <Loading/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    modalState: state.ControlUserInterface.Modal,
    accessToken: state.Account.AccessToken,
    UserAccount: state.Account.UserAccount,
});

export default withRouter(connect(mapStateToProps, )(index));
