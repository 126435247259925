import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Chat from '../../actions/Chat';
import { LoadingShow } from '../../actions/Loading';


let timeoutID;
class WaitingItem extends Component {
    state = {timeCounter: 0};
    numberRender = input => (Array(2).join(0)+input).slice(-2)
    timeRender = input => `${this.numberRender(Math.floor(input/3600))}:${this.numberRender(Math.floor(input/60%60))}:${this.numberRender(input%60)}`
    counterTime = () => this.setState({timeCounter: this.state.timeCounter+1});

    componentDidMount () {
        timeoutID = window.setInterval(this.counterTime, 1000);
    }

    componentWillUnmount () {
        window.clearInterval(timeoutID);
    }

    handleClick = () => {
        const { grabPeople, sessionID, roomGuid, Account, memberAccount, LoadingShow } = this.props;
        LoadingShow();
        grabPeople(Account.UserID, sessionID, roomGuid, memberAccount);
    }

    render() {
        const {
            memberName
        } = this.props;
        const {
            timeCounter
        } = this.state;
        return (
            <li>
                <div className="comment-box">
                    <div className="comment-head">
                        <div className="text-time">
                            <div className="text-muted">
                                {memberName}
                            </div>
                            <div>
                                {`${this.timeRender(timeCounter)}`}
                            </div>
                        </div>
                        <button className="btn" onClick={this.handleClick}>抢人</button>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = state => ({
    Account: state.Account,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
    LoadingShow,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WaitingItem);