// @flow

import React, { PureComponent } from 'react';
import radium, { StyleRoot } from 'radium';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import exclamationMark from '../../static/images/exclamation-mark.png';
import * as ModalActions from '../../actions/Modal';


const styles = {
    wrapper: {
      width: '100%',
      height: '100%',
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 10000,
    },
    boardWrapper: {
      width: 270,
      borderRadius: 6,
      boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.13)',
      backgroundColor: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 10,
      overflow: 'hidden',
    },
    titleWrapper: {
      height: 47,
      width: '100%',
      backgroundImage: 'linear-gradient(to bottom, #cede72, #adbd3d)',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleText: {
      fontSize: 24,
      fontWeight: 500,
      letterSpacing: 1,
      color: '#ffffff',
    },
    warningTextWrapper: {
      width: '100%',
      height: 120,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: '12px 5px',
      overflowY: 'auto',
      fontSize: 20,
    },
    warningText: {
      fontSize: 20,
      letterSpacing: 1,
      color: '#4a4a4a',
      margin: '1px 0',
      textAlign: 'center',
    },
    markWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 64,
      height: 64,
      borderRadius: 32,
      margin: '0 0 48px 0',
      backgroundImage: 'linear-gradient(155deg, #cede72, #adbd3d)',
    },
    mark: {
      height: 36,
    },
    iconWrapper: {
      position: 'absolute',
      right: 10,
      top: 13,
      backgroundColor: 'transparent',
      border: 0,
      width: 25,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    button: {
      height: 45,
      width: '91%',
      borderRadius: 5,
      backgroundImage: 'linear-gradient(to bottom, #4a6588 0%, #4a6588 50%, #2b3f58 50%, #2b3f58 100%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 0,
      cursor: 'pointer',
    },
    buttonText: {
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: 0.8,
      color: '#ffffff',
      cursor: 'pointer',
    },
  };

type Props = {
    showModal: Function,
    isModalShow: Boolean,
    outputString: String,
    zIndex: number,
  };
  
  type State = {
    countDown: number,
  };

class FailModal extends PureComponent<Props> {

    state = {
        countDown: 3,
      };

    componentDidMount() {
    const {
        isModalShow,
    } = this.props;

    if (isModalShow) {
        this.intervalId = setInterval(() => this.countDown(), 1000);
    }
    }

    componentDidUpdate(prevProps, prevState) {
    const {
        FailModal,
        isOpenModal,
    } = this.props;

    const {
        countDown,
    } = this.state;

    if (!prevProps.isOpenModal && isOpenModal) {
        this.intervalId = setInterval(() => this.countDown(), 1000);
    }

    if (prevProps.isOpenModal && !isOpenModal) {
        this.reset();
        clearInterval(this.intervalId);
    }

    if (prevState.countDown && !countDown) {
        FailModal(false , '');
    }
    }

    componentWillUnmount() {
    clearInterval(this.intervalId);
    }

    reset() {
    this.setState({ countDown: 3 });
    }

    countDown() {
    const {
        countDown,
    } = this.state;

    if (countDown > 0) {
        this.setState({ countDown: countDown - 1 });
    }
    }
    
  render() {
    const {
      failText,
      isOpenModal,
      FailModal,
    } = this.props;
    const {
        countDown,
    } = this.state;

    if (!isOpenModal) return null;

    return (
    <div style={[styles.wrapper]}>
        <div style={styles.boardWrapper}>
        <div style={styles.titleWrapper}>
            <span style={styles.titleText}>提示</span>
        </div>
        <div style={styles.warningTextWrapper}>
            <p style={styles.warningText}>{failText}</p>
        </div>
        <div style={styles.markWrapper}>
            <img src={exclamationMark} alt="exclamation-mark" style={styles.mark} />
        </div>
        <button
            style={styles.button}
            type="button"
            onClick={() => FailModal(false, '')}
            >
            <span style={styles.buttonText}>
            确认(
            {countDown}
            )
            </span>
        </button>
        </div>
    </div>
    );
  }
}

const reduxHook = connect(
  state => ({
    failText: state.Modal.failText,
    isOpenModal: state.Modal.isOpenModal,
  }),
  dispatch => bindActionCreators({
    ...ModalActions,
  }, dispatch),
);

export default reduxHook(radium(FailModal));
