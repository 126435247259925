import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TableFromTitle from '../../components/Template/TableFromTitle';
import TableFromContainer from './TableFromContainer';
import  * as Template  from '../../actions/Template';


class ContentPage extends Component {
    render() {
        const {
            thisPage,
            nowPage,
            pageContent,
            switchTemplateList,
        } = this.props;
        return (
            <Fragment>
                { thisPage === nowPage ?
                    <div className="tab-pane active" id="one">
                        <table className="table">
                            <TableFromTitle />
                            <TableFromContainer
                                thisPage={thisPage}
                                useDragHandle={true}
                                onSortEnd={({oldIndex, newIndex}) =>
                                    oldIndex !== newIndex &&
                                        switchTemplateList(oldIndex, newIndex, thisPage, pageContent)} />
                        </table>
                    </div>
                :
                    <div className="tab-pane">
                        <table className="table">
                            <TableFromTitle />
                            <TableFromContainer thisPage={thisPage} />
                        </table>
                    </div>
                }
            </Fragment>
        );
    }
}
const mapStateToProps = (state, {thisPage}) => ({
    nowPage: state.Template.nowPage,
    pageContent: state.Template.pageContent[thisPage],
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Template,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ContentPage);