import {
    GET_CSV_SUCCESS,
} from '../actions/CSV';
import moment from 'moment';

const initialData = {
    CSVdata: '',
};

export default (state = initialData, action) => {
    switch (action.type) {
        case GET_CSV_SUCCESS:
            return {
                ...state,
                CSVdata: action.data.map(
                    item => ({
                        '帳號': item.account,
                        '暱稱': item.name,
                        '日期': moment.unix(item.createDT).format("YYYY-MM-DD HH:mm:ss"),
                        '訊息': item.message,
                    })
                ),
            };

        default:
            return state;
    }
};
