import Axios from 'axios';
import { API_CS_HOST } from '../static/config.js';
export const SEND_SMSCODE_SUCCESS = 'MEMBERVERIFICATION/SEND_SMSCODE_SUCCESS';
export const SEND_SMSCODE_FAIL = 'MEMBERVERIFICATION/SEND_SMSCODE_FAIL';
export const GET_AUTHSMSCODE_SUCCESS = 'MEMBERVERIFICATION/GET_AUTHSMSCODE_SUCCESS';
export const GET_AUTHSMSCODE_FAIL = 'MEMBERVERIFICATION/GET_AUTHSMSCODE_FAIL';

const urlAPI = `${API_CS_HOST}`;
const urlSendSMS = '/MemberVerification/SendSMS';
const urlAuthSendSMS = '/MemberVerification/Verification';

const sendSMSCodeSuccess = data => ({
    type: SEND_SMSCODE_SUCCESS,
    data,
});
const sendSMSCodeFail = error => ({
    type: SEND_SMSCODE_FAIL,
    error,
});

const getAuthSMSCodeSuccess = data => ({
    type: GET_AUTHSMSCODE_SUCCESS,
    data,
});
const getAuthSMSCodeFail = error => ({
    type: GET_AUTHSMSCODE_FAIL,
    error,
});

export const sendSMSCode = (data) => {
    return dispatch => {
        Axios({
            url: urlAPI+urlSendSMS,
            method: 'POST',
            data: data,
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => {
            dispatch(sendSMSCodeSuccess(res.data));
        })
        .catch(error => {
            dispatch(sendSMSCodeFail(error));
        });
    };
};

export const getAuthSMSCode = (data) => {
    return dispatch => {
        Axios({
            url: urlAPI+urlAuthSendSMS,
            method: 'POST',
            data: data,
            config: { headers: { 'Conntent-Type': 'application/json' } },
        })
        .then(res => {
            dispatch(getAuthSMSCodeSuccess(res.data));
        })
        .catch(error => {
            dispatch(getAuthSMSCodeFail(error));
        });
    };
};
