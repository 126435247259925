/* eslint-disable array-callback-return */
import {
    isEmpty,
    cloneDeep,
 } from 'lodash';
import JWT from 'jwt-decode';
import {
    FETCH_GET_ONLINE_MEMBER_SUCCESS,
    FETCH_GET_ONLINE_MEMBER_FAIL,
    FETCH_GET_MESSAGE_SUCCESS,
    FETCH_GET_SESSION_HISTORY_SUCCESS,
    FETCH_GET_MEMBER_DATA_SUCCESS,
    GRABPEOPLE_SUCCESS,
    SENDMESSAGE_SUCCESS,
    UPDATE_TO_READ_SUCCESS,
    WEI_XIN_TEMPLATE_SUCCESS,
    UPLOAD_IMAGE_SUCCESS,
    GET_TEMPLATE_MESSAGE_SUCCESS,
    FETCH_GET_MoreMESSAGE_SUCCESS,
    Set_MessageButton,
    INITIAL_DATA,
    CHANGE_INPUT_PAGE,
    INITIAL_STATUS,
    OPEN_NOTICE_SOUND,
    CLOSE_NOTICE_SOUND,
} from '../actions/Chat';
import {
    WAITINGLISTADD,
    GRABPEOPLE,
    SENDMESSAGE,
    DELETECHATLIST,
    UPDATETOREAD,
    MEMBERLOGOUT,
} from '../actions/SocketAction';

const initialStatus = {
    fetchMessage: false,
    fetchMoreMessage: false,
    sendMessage: false,
    getMessage: false,
};

const initialData = {
    WaitingList: [],
    ChatList: [],
    ChatContent: [],
    Profile: {
        Content: '',
    },
    MemberID: '',
    moreMessageId:'',
    WeiXinImg: [],
    TemplateMessage: [],
    moreMessage:[],
    moreMessagebutton:true,
    Status: initialStatus,
    Pagination: {
        NowPage: 0,
        TotalPage: 0,
        InputPage: 0,
    },
    MessageID: {
        prevMoreMessageID: 0,
        MoreMessageID: 0,
    },
    LastTenMessageID:[],
    noticeSound: false,
};

export default ( state = initialData, action) => {
    let LastTenMessageArray = state.LastTenMessageID;
    switch(action.type) {
        case INITIAL_DATA:
            return initialData;
        case FETCH_GET_ONLINE_MEMBER_SUCCESS:{
        
            return {
                ...state,
                WaitingList: action.data.data.onlineMember,
                Status: initialStatus,
            };
        }
        case OPEN_NOTICE_SOUND: {
            return {
                ...state,
                noticeSound: true,
            };
        }
        case CLOSE_NOTICE_SOUND: {
            return {
                ...state,
                noticeSound: false,
            }; 
        }
        
        case FETCH_GET_ONLINE_MEMBER_FAIL:
            return {
                ...state,
                error: action.error || '',
                Status: initialStatus,
            };
        case CHANGE_INPUT_PAGE:
            return {
                ...state,
                Pagination:{
                    ...state.Pagination,
                    InputPage: action.InputPage,
                },
            };
        case FETCH_GET_SESSION_HISTORY_SUCCESS:
            if (action.data.data.pageInfo.nowPage > action.data.data.pageInfo.totalPage) return state;
            if (action.data.data.pageInfo.nowPage < 1 ) return state;
            return {
                ...state,
                ChatList: action.data.data.sessionHistory.map(item => ({...item, showTitle: false})),
                Pagination: {
                    NowPage: action.data.data.pageInfo.nowPage,
                    TotalPage: action.data.data.pageInfo.totalPage,
                    InputPage: action.data.data.pageInfo.nowPage,
                },
                Status: initialStatus,
            };
        case FETCH_GET_MESSAGE_SUCCESS:
            return {
                ...state,
                ChatList: state.ChatList
                    .map(item => ({...item, showTitle: false}))
                    .reduce((prev, curr) => {
                        if(curr.roomGuid === action.data.data.roomGuid) curr.showTitle = true;
                        return [...prev, curr];
                    },[]),
                ChatContent: action.data.data.messageList,
                MemberID: action.MemberID,
                moreMessageId:action.data.data.moreMessageID,
                MessageID: {
                    prevMoreMessageID: action.data.data.moreMessageID,
                    MoreMessageID: action.data.data.moreMessageID,
                },
                Status: {
                    ...initialStatus,
                    fetchMessage: true,
                },
            };

        case FETCH_GET_MoreMESSAGE_SUCCESS:
                return {
                    ...state,
                    ChatContent:[
                        ...action.data.data.messageList,
                        ...state.ChatContent
                    ],
                    moreMessageId:action.data.data.moreMessageID,
                    Status: {
                        ...initialStatus,
                        fetchMoreMessage: true,
                    },
                    MessageID: {
                        prevMoreMessageID: state.MessageID.MoreMessageID,
                        MoreMessageID: action.data.data.moreMessageID,
                    }
                };

        case Set_MessageButton:
            return {
                ...state,
                moreMessagebutton:false,
                Status: initialStatus,
            };

        case FETCH_GET_MEMBER_DATA_SUCCESS:
            return {
                ...state,
                Profile: {
                    Content: action.data,
                },
            };
        case SENDMESSAGE_SUCCESS:
            if (LastTenMessageArray.indexOf(action.data.data.resultModel.messageID) !== -1)
                return state;
            LastTenMessageArray.push(action.data.data.resultModel.messageID);
            if (LastTenMessageArray.length > 10) LastTenMessageArray.shift();
            return {
                ...state,
                ChatList: [
                    {
                        ...state.ChatList.filter(item => item.roomGuid === action.data.data.resultModel.roomGuid)[0],
                        lastText: action.data.data.resultModel.sessionContent,
                        isRead: 1,
                        isSessionEnd: action.data.data.resultModel.isSessionEnd,
                        createDt: Math.floor(action.data.data.resultModel.createDt),
                    },
                    ...state.ChatList.filter(item => item.roomGuid !== action.data.data.resultModel.roomGuid)
                ],
                ChatContent: [...state.ChatContent, {...action.data.data.resultModel, right: true}],
                Status: {
                    ...initialStatus,
                    sendMessage: true,
                },
                LastTenMessageID: LastTenMessageArray,
            };
        case UPDATE_TO_READ_SUCCESS: {
            // if(isEmpty(state.ChatContent) || state.ChatContent[0].roomGuid !== action.data.data.resultModel.roomGuid) return state;
            return {
                ...state,
                ChatList: cloneDeep(state.ChatList).map(item => (item.roomGuid === action.data.data.resultModel.roomGuid ? {...item, isRead: 1}: item)),
                ChatContent: state.ChatContent.reduce((prev, curr) => {
                    if(curr.fromAccount === state.ChatList.filter(item => item.roomGuid === action.data.data.resultModel.roomGuid)[0].memberAccount) curr.isRead = 1;
                    return[...prev, curr];
                }, []),
                Status: initialStatus,
            };
        }
        case WEI_XIN_TEMPLATE_SUCCESS: {
            return {
                ...state,
                WeiXinImg: action.data.data.resultModel,
                Status: initialStatus,
            };
        }
        case UPLOAD_IMAGE_SUCCESS: {
            if (LastTenMessageArray.indexOf(action.data.data.resultModel.messageID[0]) !== -1)
                return state;

            LastTenMessageArray.push(action.data.data.resultModel.messageID[0]);
            if (LastTenMessageArray.length > 10) LastTenMessageArray.shift();
            return {
                ...state,
                ChatList: [
                    {
                        ...state.ChatList.filter(item => item.roomGuid === action.data.data.resultModel.roomGuid)[0],
                        lastText: action.data.data.resultModel.sessionContent,
                        isRead: 1,
                        createDt: Math.floor(action.data.data.resultModel.createDt[0]),
                    },
                    ...state.ChatList.filter(item => item.roomGuid !== action.data.data.resultModel.roomGuid)
                ],
                ChatContent: [
                    ...state.ChatContent,
                    ...action.data.data.resultModel.content.reduce((prev, curr, key) => {
                        return [...prev, {
                            ...action.data.data.resultModel,
                            content: curr,
                            createDt:action.data.data.resultModel.createDt[key],
                        }];
                    }, []).map( item => ({ ...item, right: true})),
                ],
                Status: {
                    ...initialStatus,
                    sendMessage: true,
                },
                LastTenMessageID: LastTenMessageArray,
            };
        }
        case GET_TEMPLATE_MESSAGE_SUCCESS:
            return {
                ...state,
                TemplateMessage: action.data.data.listTemplate,
                Status: initialStatus,
            };
        case DELETECHATLIST:
            return {
                ...state,
                ChatList: state.ChatList.filter(item => item.roomGuid !== action.data.roomGuid),
                Status: initialStatus,
            };
        case WAITINGLISTADD:
            let isShow = false;
            if(localStorage.getItem('accessToken')){
                JWT(localStorage.getItem('accessToken')).DepartmentList.map(item => action.data.departmentList.map(subItem => {
                    if(item === subItem) isShow = true;
                }));
            }
            if(isShow) {
                isShow = false;
                JWT(localStorage.getItem('accessToken')).FunctionList.map(item => {
                    if(item === action.data.functionID) isShow = true;
                });
            }
            if(!isShow) return state;
            if(state.WaitingList.map(item => item.roomGuid).indexOf(action.data.roomGuid) !== -1)return state;
            if(state.ChatContent.length > 0 && state.ChatContent[0].roomGuid === action.data.roomGuid) {
                return {
                    ...state,
                    WaitingList: [...state.WaitingList, action.data],
                    ChatContent: [],
                    Status: initialStatus,
                    noticeSound: true,
                };
            }
            return {
                ...state,
                WaitingList: [...state.WaitingList, action.data],
                ChatList: state.ChatList.filter(item => item.roomGuid !== action.data.roomGuid),
                Status: initialStatus,
                noticeSound: true,
            };
        case GRABPEOPLE:
            if(action.data.userID !== JWT(localStorage.getItem('accessToken')).UserID) {
                return {
                    ...state,
                    WaitingList: state.WaitingList.filter(item => item.roomGuid !== action.data.roomGuid),
                };

            }
            if(state.ChatList.map(item => item.roomGuid).indexOf(action.data.roomGuid) !== -1)return state;
            if(state.ChatContent.length > 0 && state.ChatContent[0].roomGuid === action.data.roomGuid) {
                return {
                    ...state,
                    WaitingList: state.WaitingList.filter(item => item.roomGuid !== action.data.roomGuid),
                    ChatContent: [],
                    ChatList: [action.data, ...state.ChatList],
                    Status: initialStatus,
                };
            }
            return {
                ...state,
                WaitingList: state.WaitingList.filter(item => item.roomGuid !== action.data.roomGuid),
                ChatList: [action.data, ...state.ChatList],
                Status: initialStatus,
            };
        case SENDMESSAGE:
            const socketMessageID = typeof action.data.messageID !== 'number' ? action.data.messageID[0] : action.data.messageID;
            if (action.data.toID !== JWT(localStorage.getItem('accessToken')).UserID && action.data.fromID !== JWT(localStorage.getItem('accessToken')).UserID)
                return state;
            if (LastTenMessageArray.indexOf(socketMessageID) !== -1)
                return state;

            LastTenMessageArray.push(socketMessageID);
            if (LastTenMessageArray.length > 10) LastTenMessageArray.shift();
            // 沒有點選的聊天室
            if(state.ChatContent.length === 0) {
                return {
                   ...state,
                    ChatList: action.data.contentType === 3 ? (
                        state.ChatList.map(item => {
                            if (item.roomGuid === action.data.roomGuid){
                                return {
                                    ...item,
                                    isSessionEnd: 1,
                                };
                            }
                            return item;
                        })
                    ) : (
                        [
                            {
                                ...state.ChatList.filter(item => item.roomGuid === action.data.roomGuid)[0],
                                lastText: action.data.contentType === 2 ? action.data.sessionContent : action.data.content,
                                isRead: (action.data.contentType === 1 && action.data.fromType === 0) ? 1 : action.data.isRead,
                                createDt: action.data.contentType === 2 ? Math.floor(action.data.createDt[0]) : Math.floor(action.data.createDt),
                            },
                            ...state.ChatList.filter(item => item.roomGuid !== action.data.roomGuid)
                        ]
                    ),
                  Status: initialStatus,
                };
            }
            // 接收到的訊息不是當前聊天室的
            if(state.ChatContent[0].sessionID !== action.data.sessionID) {
                return {
                    ...state,
                    ChatList: action.data.contentType === 3 ? (
                        state.ChatList.map(item => {
                            if (item.roomGuid === action.data.roomGuid){
                                return {
                                    ...item,
                                    isSessionEnd: 1,
                                };
                            }
                            return item;
                        })
                    ) : (
                        [
                            {
                                ...state.ChatList.filter(item => item.roomGuid === action.data.roomGuid)[0],
                                lastText: action.data.contentType === 2 ? action.data.sessionContent : action.data.content,
                                isRead: action.data.fromType === 0 ? 1 : action.data.isRead,
                                createDt: action.data.contentType === 2 ? Math.floor(action.data.createDt[0]) : Math.floor(action.data.createDt),
                            },
                            ...state.ChatList.filter(item => item.roomGuid !== action.data.roomGuid)
                        ]
                    ),
                    Status: initialStatus,
                };
            }
            // 接收到的訊息是當前聊天室的
            if(state.ChatContent[0].sessionID === action.data.sessionID) {
                // 圖片訊息
                if(action.data.contentType === 2) {
                    return {
                        ...state,
                        ChatList: [
                            {
                                ...state.ChatList.filter(item => item.roomGuid === action.data.roomGuid)[0],
                                lastText: action.data.sessionContent,
                                isRead: action.data.fromType === 0 ? 1 : action.data.isRead,
                                createDt: Math.floor(action.data.createDt[0]),
                            },
                            ...state.ChatList.filter(item => item.roomGuid !== action.data.roomGuid)
                        ],
                        ChatContent: [
                            ...state.ChatContent,
                            ...action.data.content.reduce((prev, curr, key) => {
                                return [...prev, {
                                    ...action.data,
                                    content: curr,
                                    createDt:action.data.createDt[key],
                                }];
                            }, []),
                        ],
                        Status: {
                            ...initialStatus,
                            ...action.data.toType === 0 && action.data.fromType === 1 ? {getMessage:true} : {sendMessage:true},
                        },
                        LastTenMessageID: LastTenMessageArray,
                    };
                }
                // 系統訊息
                if(action.data.contentType === 3) {
                    return {
                        ...state,
                        ChatList:
                            state.ChatList.map(item => {
                                if (item.roomGuid === action.data.roomGuid){
                                    return {
                                        ...item,
                                        isSessionEnd: 1,
                                    };
                                }
                                return item;
                            }),
                        ChatContent: [...state.ChatContent, action.data],
                        Status: initialStatus,
                        LastTenMessageID: LastTenMessageArray,
                    };
                }
                // 文字訊息
                return {
                    ...state,
                    ChatList: [
                        {
                            ...state.ChatList.filter(item => item.roomGuid === action.data.roomGuid)[0],
                            lastText: action.data.content,
                            isRead: action.data.fromType === 0 ? 1 : action.data.isRead,
                            createDt: Math.floor(action.data.createDt),
                        },
                        ...state.ChatList.filter(item => item.roomGuid !== action.data.roomGuid)
                    ],
                    ChatContent: [...state.ChatContent, action.data],
                    Status: {
                        ...initialStatus,
                        ...action.data.toType === 0 && action.data.fromType === 1 ? {getMessage:true} : {sendMessage:true},
                    },
                    LastTenMessageID: LastTenMessageArray,
                };
            }
            return state;

        case UPDATETOREAD:
            if(action.data.toID !== JWT(localStorage.getItem('accessToken')).UserID && action.data.fromID !== JWT(localStorage.getItem('accessToken')).UserID)
                return state;
            if(action.data.fromID === JWT(localStorage.getItem('accessToken')).UserID) return state;
            if(isEmpty(state.ChatContent)) {
                return {
                    ...state,
                    ChatList: action.data.fromType === 0 ? state.ChatList.reduce((prev, curr) => {
                        if(curr.roomGuid === action.data.roomGuid) curr.isRead = 1;
                        return [...prev, curr];
                    }, [])
                    : state.ChatList,
                };
            }
            return {
                ...state,
                ChatList: action.data.fromType === 0 ? state.ChatList.reduce((prev, curr) => {
                    if(curr.roomGuid === action.data.roomGuid) curr.isRead = 1;
                    return [...prev, curr];
                }, [])
                : state.ChatList,
                ChatContent: state.ChatContent.reduce((prev, curr) => {
                    if(curr.fromAccount === state.ChatList.filter(item => item.roomGuid === action.data.roomGuid)[0].userAccount) curr.isRead = 1;
                    return[...prev, curr];
                }, []),
                Status: initialStatus,
            };
        case MEMBERLOGOUT:
            return {
                ...state,
                WaitingList: state.WaitingList.filter(item => item.roomGuid !== action.data.roomGuid),
            };
        case INITIAL_STATUS:
            return {
                ...state,
                Status: initialStatus,
            };
        default:
            return state;
    }
};