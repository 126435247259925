import Axios from 'axios';
import JWT from 'jwt-decode';
import { handleNetworkError } from './ErrorHandle';
import { API_CS_HOST } from '../static/config';
export const GET_MEMBER_TOKEN_SUCCESS = 'MEMBER/GET_MEMBER_TOKEN_SUCCESS';
export const GET_MEMBER_TOKEN_FAIL = 'MEMBER/GET_MEMBER_TOKEN_FAIL';
export const CLEAR_MEMBER_TOKEN = 'MEMBER/CLEAR_MEMBER_TOKEN';
export const REMOVE_MEMBER_TOKEN_SUCCESS = 'MEMBER/REMOVE_MEMBER_TOKEN_SUCCESS';

const urlMemberLogin = `${API_CS_HOST}/Account/Login`;
const urlMemberLogout = `${API_CS_HOST}/Account/Logout`;

export const getMemberTokenSuccess = data => {
    if(data.statusCode !== 0) return {
        type: GET_MEMBER_TOKEN_FAIL,
        error: data.message,
    };
    localStorage.setItem('accessToken', data.data.token);
    Axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.accessToken}` || null;
    return {
        type: GET_MEMBER_TOKEN_SUCCESS,
        data: JWT(data.data.token),
    };
};

export const getMemberTokenFail = error => ({
    type: GET_MEMBER_TOKEN_FAIL,
    error,
});

export const getMemberToken = payload => {
    return dispatch => {
        Axios.post(urlMemberLogin, payload)
        .then(res => dispatch(getMemberTokenSuccess(res.data)))
        .catch(error => dispatch(handleNetworkError(error.response)));
    };
};

export const clearMemberTokenSuccess = () => {
    localStorage.removeItem('accessToken');
    return {type: CLEAR_MEMBER_TOKEN};
};

export const clearMemberToken = () => {
    return dispatch => {
        localStorage.removeItem('accessToken');
        dispatch({type: CLEAR_MEMBER_TOKEN});
        Axios.post(urlMemberLogout)
            .then(res => dispatch({type: REMOVE_MEMBER_TOKEN_SUCCESS}))
            .catch(error => dispatch(handleNetworkError(error.response)));
    };
};
