import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Chat from '../../actions/Chat';
import WaitingItem from '../../components/Chat/WaitingItem.js';
import WaitingSound from '../../components/Chat/WaitingSound.js';

class WaitingList extends Component {

    componentDidMount() {
        const {
            UserAccount,
            getOnlineMember,
        } = this.props;
        if(UserAccount !== 'csfeedback')getOnlineMember();
    }

    render() {
        return (
            <div className="col-md-3 col-lg-2 bg-dark text-white" style={{minWidth: '220px'}}>
                <div className="text-chatwait-title">
                    等待列表
                </div>
                <ul className="onlineUser">
                    {this.props.data && this.props.data.map( item => <WaitingItem key={item.roomGuid} {...item}/>)}
                </ul>
                {this.props.data.length > 0
                ?
                <WaitingSound />
                :
                null
                }
                
            </div>
        );
    }
}
const mapStateToProps = state => ({
    data: state.Chat.WaitingList,
    UserAccount: state.Account.UserAccount,
    sound: state.Chat.noticeSound,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Chat,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WaitingList);