export const OPEN_WEIXIN = 'UI/OPEN_WEIXIN';
export const CLOSE_WEIXIN = 'UI/CLOSE_WEIXIN';
export const OPEN_TEMPLATE_MESSAGE = 'UI/OPEN_TEMPLATE_MESSAGE';
export const CLOSE_TEMPLATE_MESSAGE = 'UI/CLOSE_TEMPLATE_MESSAGE';
export const OPEN_SUBPAGE_TEMPLATE_MESSAGE = 'UI/OPEN_SUBPAGE_TEMPLATE_MESSAGE';
export const CLOSE_SUBPAGE_TEMPLATE_MESSAGE = 'UI/CLOSE_SUBPAGE_TEMPLATE_MESSAGE';
export const OPEN_MODAL = 'UI/OPEN_MODAL';
export const CLOSE_MODAL = 'UI/CLOSE_MODAL';

export const openWeiXin = () => ({ type: OPEN_WEIXIN });
export const closeWeiXin = () => ({ type: CLOSE_WEIXIN });
export const setTemplateMessage = () => ({type: OPEN_TEMPLATE_MESSAGE});
export const opneTemplateMessage = () => {
    return dispatch => {
        dispatch(closeSubpageTemplateMessage());
        dispatch(setTemplateMessage());
    };
};
export const closeTemplateMessage = () => ({type: CLOSE_TEMPLATE_MESSAGE});
export const setSubpageOpenTemplateMessage = numberPage => ({ type: OPEN_SUBPAGE_TEMPLATE_MESSAGE, Page: numberPage });
export const openSubpageTemplateMessage = numberPage => {
    return dispatch => {
        dispatch(closeSubpageTemplateMessage());
        dispatch(setSubpageOpenTemplateMessage(numberPage));
    };
};
export const closeSubpageTemplateMessage = () => ({ type: CLOSE_SUBPAGE_TEMPLATE_MESSAGE });
export const openModal = imagePath => ({ type: OPEN_MODAL, data: imagePath });
export const closeModal = () => ({ type: CLOSE_MODAL });