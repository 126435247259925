import {
    FAIL_MODAL,
} from '../actions/Modal';

const initState = {
    isOpenModal: false,
    failText: '',
};

export default (state = initState, action) => {
    switch (action.type) {
        case FAIL_MODAL:
            return {
                ...state,
                isOpenModal: action.isOpen,
                failText: action.failText,
            };
        default:
            return state;
    }
};