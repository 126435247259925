import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import Chat from './Chat';
import MainChat from './MainChat';
import Account from './Account';
import ControlUserInterface from './ControlUserInterface';
import History from './History';
import Template from './Template';
import MemberVerification from './MemberVerification';
import Range from './Range';
import CSV from './CSV';
import Modal from './Modal';
import Loading from './Loading';
import Ezagent from './Ezagent';

export default combineReducers({
    Chat,
    MainChat,
    Account,
    ControlUserInterface,
    History,
    Template,
    MemberVerification,
    Range,
    form: formReducer,
    CSV,
    Modal,
    Loading,
    Ezagent
});