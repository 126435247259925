import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ControlUserInterface from '../../actions/ControlUserInterface';
import * as History from '../../actions/History';
import { LoadingShow } from '../../actions/Loading';
import ChatHistoryItem from '../../components/History/ChatHistoryItem';

const styles ={
    dropdown:{
        backgroundColor:'black',
        position:'absolute',
        bottom:'50px',
        right:'50px',
        zIndex: "200",
        fontSize:'20px',
        color:'#fff',
        width:'30px',
        height:'30px',
        borderRadius:'15px',
        textAlign:'center',
        cursor: 'pointer',
    },
};
class ChatHistory extends Component {
    constructor(props) {
        super(props);
        this.containerChatList = React.createRef();
        this.state = {
            isBottom: false,
        };
    }

    scroll = data => {
        this.containerChatList.current.scroll(0, data);
        this.isBottom();
        this.props.initialStatus();
    }

    scrollTop = () =>
        this.containerChatList.current.scroll(0, 0);

    scrollBottom = () =>
        this.containerChatList.current.scroll(0, this.containerChatList.current.scrollHeight);   

    handleGetMoreMessage = () => {
        const {
            getMoreMessage,
            MoreMessageID,
            Member,
        } = this.props;
        getMoreMessage({
            sessionID: Member.sessionID,
            roomGuid: Member.roomGuid,
            moreMessageID: MoreMessageID,
        });
        this.props.LoadingShow();
    };
    componentDidMount() {
        const {
            MessageStatus:{
                StatusKeyWordMessage,
                StatusGetMessage,
            },
            Content,
            initialStatus,
        } = this.props;
        if (StatusKeyWordMessage && Content.length > 0) {
            this.scrollTop();
            this.isBottom();
            initialStatus();
        }
        else if (StatusGetMessage && Content.length > 0) {
            this.scrollBottom();
            this.isBottom();
            initialStatus();
        }
    }
    componentDidUpdate() {
        const {
            MessageStatus:{
                StatusKeyWordMessage,
                StatusGetMessage,
                StatusSocketMessage,
            },
            Content,
            initialStatus,
        } = this.props;
        if (StatusKeyWordMessage && Content.length > 0) {
            this.scrollTop();
            this.isBottom();
            initialStatus();
        }
        else if (StatusGetMessage && Content.length > 0) {
            this.scrollBottom();
            this.isBottom();
            initialStatus();
        }
        else if (StatusSocketMessage) {
            this.isBottom();
            initialStatus();
        }
    }
    isBottom = () => {
        if(this.containerChatList.current === null) return this.setState({isBottom: false});
        if(this.containerChatList.current.scrollTop === this.containerChatList.current.scrollHeight - this.containerChatList.current.clientHeight)
            this.setState({isBottom: true});
        else
            this.setState({isBottom: false});
    }
    bottomIcon = () => (
        <div
            style={styles.dropdown}
            onClick={this.scrollBottom}>
            <i className="fa fa-caret-down" />
        </div>
    );

    render() {
        const {
            Content,
            openModal,
            Member,
            getMessage,
            StatusMoreMessage,
            MoreMessageID,
            PreMoreMessageID,
            SearchKeyWordString,
        } = this.props;
        if (Content.length === 0 && Member === undefined) return null;
        return (
            <Fragment>
                <div className="col-md-9 Chat-Content-Body bk-blue" style={{display: 'flex'}}>
                    <div className="text-chat-title">
                        {Member.memberName} <small className="text-muted">{Member.memberAccount}</small>
                        <i className="fa fa-refresh" title="重新整理" onClick={ ()=> getMessage(Member)}></i>
                        <br />
                    </div>
                    {Content.length > 0 &&
                        <ul className="chat style-2" ref={this.containerChatList} onScroll={this.isBottom}>
                            <li>
                                {MoreMessageID !== 0 ?
                                    <button
                                        id="BtnGetMoreMessage"
                                        onClick={this.handleGetMoreMessage}
                                        className="btn">
                                        载入更多
                                    </button> : <div id="NotMoreMessage">没有更多讯息</div>
                                }
                            </li>
                            {!this.state.isBottom && this.bottomIcon()}
                            {Content.map((item, index) => {
                                if (item.sessionID === Member.sessionID && item.roomGuid === Member.roomGuid ) {
                                    return(
                                        <ChatHistoryItem
                                        key={index}
                                        openModal={openModal}
                                        scroll={this.scroll}
                                        status={{
                                            StatusMoreMessage,
                                            MoreMessageID,
                                            PreMoreMessageID,
                                            SearchKeyWordString,
                                        }}
                                        {...item} />
                                    );
                                }
                                return null;
                            }
                            )}
                        </ul>
                    }
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    Content: state.History.Content,
    MoreMessageID: state.History.MessageID.MoreMessageID,
    PreMoreMessageID: state.History.MessageID.PreMoreMessageID,
    Member: state.History.Member,
    MessageStatus: state.History.MessageStatus,
    StatusMoreMessage: state.History.MessageStatus.StatusMoreMessage,
    SearchKeyWordString: state.History.SearchData.SearchKeyWordString,
    // StatusGetMessage: state.History.MessageStatus.StatusGetMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...ControlUserInterface,
    ...History,
    LoadingShow,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChatHistory);