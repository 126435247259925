import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Template from '../../actions/Template';
import GroupItem from '../../components/Template/GroupItem';

class GroupContainer extends Component {
    render() {
        const {
            pageList,
            nowPage,
            changeNowPage,
        } = this.props;
        return (
            <Fragment>
                <ul className="nav nav-tabs">
                    { pageList.map( (item, key) =>
                        <GroupItem
                            key={`GroupItem-${key}`}
                            data={{item, nowPage, changeNowPage}} />)
                    }
                </ul>
            </Fragment>
        );
    }
}


const mapStateToProps = state => ({
    pageList: state.Template.pageList,
    nowPage: state.Template.nowPage,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...Template,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GroupContainer);