import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as History from '../../actions/History';


class PaginationContainer extends Component {
    createPaginationArray = (NowPage, TotalPage) => {
        if(TotalPage ===1 ) return [1] ;
        if(TotalPage === 2) return [1, 2];
        else if(NowPage === 1) return [1, 2, 3];
        else if(NowPage === TotalPage) return [TotalPage-2, TotalPage-1, TotalPage];
        return [NowPage-1, NowPage, NowPage+1];
    };

    PaginationItem = (indexNumber, itemNumber, NowPage, getSessionHistory, SearchString, SearchKeyWordString) => {
            return(
                <Fragment key={indexNumber}>
                    { itemNumber === NowPage ?
                        <li className="paginate_button page-item active"
                            style={{ cursor: 'pointer'}}
                            onClick={() => getSessionHistory(SearchString, itemNumber, SearchKeyWordString)}>
                            <div className="page-link">{itemNumber}</div>
                        </li>
                        :
                        <li className="paginate_button page-item"
                            style={{ cursor: 'pointer'}}
                            onClick={() => getSessionHistory(SearchString, itemNumber, SearchKeyWordString)}>
                            <div className="page-link">{itemNumber}</div>
                        </li>
                    }
                </Fragment>
            );
    }

    render() {
        const {
            getSessionHistory,
            SearchString,
            SearchKeyWordString,
            Pagination: {
                NowPage,
                TotalPage,
            }
        } = this.props;

        return (
            <Fragment>
                { this.createPaginationArray(NowPage, TotalPage).map(
                    (item, index) => this.PaginationItem(index, item, NowPage, getSessionHistory, SearchString, SearchKeyWordString)
                )}
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    Pagination: state.History.Pagination,
    SearchKeyWordString: state.History.SearchData.SearchKeyWordString,
    SearchString: state.History.SearchData.SearchString,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    ...History,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaginationContainer);