import socketIOClient from 'socket.io-client';
import Axios from 'axios';
import { handleNetworkError } from './ErrorHandle';
import { API_CS_HOST } from '../static/config';

export const DELETECHATLIST = 'SOCKET/DELETECHATLIST';
export const WAITINGLISTADD = 'SOCKET/WAITINGLISTADD';
export const GRABPEOPLE = 'SOCKET/GRABPEOPLE';
export const SENDMESSAGE = 'SOCKET/SENDMESSAGE';
export const UPDATETOREAD = 'SOCKET/UPDATETOREAD';
export const MEMBERLOGOUT = 'SOCKET/MEMBERLOGOUT';
export const FETCH_GET_ONLINE_MEMBER_SUCCESS = 'CHAT/FETCH_GET_ONLINE_MEMBER_SUCCESS';

const GetOnlineMember = `${API_CS_HOST}/Chat/GetOnlineMember`;
let reconnectID1 = 0;
let reconnectID2 = 0;
let reconnectID3 = 0;
let reconnectID4 = 0;
let reconnectID5 = 0;

export default class SocketAction {
    constructor({store}) {
        this.store = store;
    }

    listen(path) {
        this.socket = socketIOClient(path);
        this.socket.emit('login', `CS^${localStorage.accessToken}`);
        this.socket.on('DeleteChatList', input => this.socketDeleteChatList(input));
        this.socket.on('WaitingListAdd', input => this.socketWaitingListAdd(input));
        this.socket.on('GrabPeople', input => this.socketGrabPeople(input));
        this.socket.on('SendMessage', input => this.socketSendMessage(input));
        this.socket.on('UpdateToRead', input => this.socketUpdateToRead(input));
        this.socket.on('MemberLogout', input => {
            this.socketSendMessage(input);
            this.socketMemberLogout(input);
        });
        this.socket.on('reconnect', () => {
            this.socket.emit('login', `CS^${localStorage.accessToken}`);
            this.getOnlineMember();
        });
        window.addEventListener('online', e => this.handleConnectionChange(e));
        window.addEventListener('offline', e => this.handleConnectionChange(e));

    }

    logout() {
        this.socket.disconnect();
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);
    }

    socketDeleteChatList = input => {
        this.store.dispatch({
            type: DELETECHATLIST,
            data: {
                roomGuid: input.RoomGuid,
            }
        });
    }

    socketWaitingListAdd = input => {
        this.store.dispatch({
            type: WAITINGLISTADD,
            data: {
                memberAccount: input.MemberAccount,
                memberName: input.MemberName,
                roomGuid: input.RoomGuid,
                sessionID: input.SessionID,
                departmentList: input.DepartmentList,
                functionID: input.FunctionID,
            },
        });
    }

    socketGrabPeople = input => {
        this.store.dispatch({
            type: GRABPEOPLE,
            data: {
                channelType: input.ChannelType,
                eventType: input.EventType,
                functionID: input.FunctionID,
                isSessionEnd: input.IsSessionEnd,
                isSessionEndTime: input.IsSessionEndTime,
                lastText: input.LastText,
                memberAccount: input.MemberAccount,
                memberName: input.MemberName,
                memberID: input.MemberID,
                positionLevelID: input.PositionLevelID,
                roomGuid: input.RoomGuid,
                sessionID: input.SessionID,
                timeStamp: input.TimeStamp,
                userAccount: input.UserAccount,
                userID: input.UserID,
                createDt: input.CreateDt,
            },
        });
    }

    socketSendMessage = input => {
        this.store.dispatch({
            type: SENDMESSAGE,
            data: {
                sessionID: input.SessionID,
                roomGuid: input.RoomGuid,
                fromID: input.FromID,
                toID: input.ToID,
                fromAccount: input.FromAccount,
                toAccount: input.ToAccount,
                fromType: input.FromType,
                toType: input.ToType,
                channelType: input.ChannelType,
                contentType: input.ContentType,
                content: input.Content,
                timeStamp: input.TimeStamp,
                createDt: input.CreateDt,
                isRead: input.IsRead,
                sessionContent: input.SessionContent,
                messageID: input.MessageID,
                isSessionEnd: input.IsSessionEnd,
            },
        });
    }

    socketUpdateToRead = input => {
        this.store.dispatch({
            type: UPDATETOREAD,
            data: {
                fromID: input.FromID,
                roomGuid: input.RoomGuid,
                sessionID: input.SessionID,
                toID: input.ToID,
                fromType: input.FromType,
                toType: input.ToType,
            }
        });
    }

    socketMemberLogout = input => {
        this.store.dispatch({
            type: MEMBERLOGOUT,
            data: {
                roomGuid: input.RoomGuid,
                sessionID: input.SessionID,
            },
        });
    }

    handleConnectionChange = (event, socketKeyword, socketGS_AccountID) => {
        if (event.type === 'offline') {
          console.log('CS socket: You lost connection.');
        }
        if (event.type === 'online') {
          console.log('CS socket: You are now back online.');
          this.socket.emit('login', `CS^${localStorage.accessToken}`);
          this.getOnlineMember();
          window.clearTimeout(reconnectID1);
          window.clearTimeout(reconnectID2);
          window.clearTimeout(reconnectID3);
          window.clearTimeout(reconnectID4);
          window.clearTimeout(reconnectID5);
          reconnectID1 = window.setTimeout( () => {
            console.log(`reconnect1`);
            this.socket.emit('login', `CS^${localStorage.accessToken}`);
          }, 1000);
          reconnectID2 = window.setTimeout( () => {
            console.log(`reconnect2`);
            this.socket.emit('login', `CS^${localStorage.accessToken}`);
          }, 2000);
          reconnectID3 = window.setTimeout( () => {
            console.log(`reconnect3`);
            this.socket.emit('login', `CS^${localStorage.accessToken}`);
          }, 3000);
          reconnectID4 = window.setTimeout( () => {
            console.log(`reconnect4`);
            this.socket.emit('login', `CS^${localStorage.accessToken}`);
          }, 4000);
          reconnectID5 = window.setTimeout( () => {
            console.log(`reconnect5`);
            this.socket.emit('login', `CS^${localStorage.accessToken}`);
            this.getOnlineMember();
          }, 5000);
        }
    }

    getOnlineMember = () => {
        Axios.post(GetOnlineMember)
            .then(res => this.store.dispatch( {type: FETCH_GET_ONLINE_MEMBER_SUCCESS, data: res.data} ))
            .catch(error => this.store.dispatch(handleNetworkError(error.response)));
    };
}