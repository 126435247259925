import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChatHistory from './History/ChatHistory';
import SearchList from './History/SearchList';
import MemberInfoBar from './History/MemberInfoBar';

class History extends Component {
    render() {
        const { Member } = this.props;
        return (
            <div className="content-wrapper">
                <div className="container-fluid" id="container">
                    <div className="row ListChat History">
                        <div className="col-lg-8 heigh100 ">
                            <div className="row">
                                {Member && <MemberInfoBar />}
                                {Member && <ChatHistory />}
                            </div>
                        </div>
                        <SearchList />
                    </div>
                    <input type="hidden" id="displayGroup" value="CustomeMin" />

                    <input type="hidden" id="hiddenFrom" value="cs01" />
                    <input type="hidden" id="hiddenTo" value="" />
                    <input type="hidden" id="hiddenBill" />
                    <input type="hidden" id="SessionID" value="" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    Member: state.History.Member,
});

export default connect(mapStateToProps)(History);