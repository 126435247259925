import Axiox from 'axios';
import { API_CS_HOST } from '../static/config';
import { handleNetworkError } from './ErrorHandle';

export const GET_CSV_SUCCESS = 'CSV/GET_CSV_SUCCESS';

const urlFeedbackDownload = `${API_CS_HOST}/Feedback/FeedbackDownload`;

export const getCSV = ({startDT, endDT}, done) => dispatch => {
    Axiox({
        url: urlFeedbackDownload,
        method: 'post',
        data: {
            startDT,
            endDT,
        },
        config: { headers: { 'Conntent-Type': 'application/json' } },
    })
    .then(res => {
        dispatch({type: GET_CSV_SUCCESS, data: res.data.data});
    })
    .catch(error => dispatch(handleNetworkError(error)));
};
