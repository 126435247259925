import React, { Component } from 'react';
import {
    compose,
    bindActionCreators,
} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import './styles.css';
import Radium from 'radium';
import * as Loading from '../../actions/Loading';

const styles = {
    wrapper: {
        display:'flex',
        flexDirection: 'column',
        width: '80%',
        height: 'calc(100vh - 420px)',
        overflow: 'scroll',
        paddingBottom: '30px',
        whiteSpace: 'nowrap',
    },
    thB: {
        flex: 2,
        textAlign: 'center',
        backgroundColor: '#4CAF50',
        color: '#fff',
        width: '100%',
        fontSize: '15px',
        minWidth: '30px',
    },
    th: {
        flex: 1,
        textAlign: 'left',
        backgroundColor: '#4CAF50',
        color: '#fff',
        width: '100%',
        fontSize: '15px',
        minWidth: '30px',
    },
    text: {
        border: 0,
        fontSize: '20px',
        fontWeight: 'bold',
        margin: '10px',
        color: 'black',
    },

};

let DateList = [];
for(let i=1 ;i<=31;i++){
    DateList.push(<th key={i}>{i}日</th>);
}

class RangeTabel extends Component{

    shouldComponentUpdate(nextProps, nextState) {
        if(this.props.reportData !== nextProps.reportData)
            return true;
        else
            return false;
    }

    componentDidUpdate(){
        const {LoadingHide} = this.props;
        LoadingHide();
    }

    totalTable(data, StartDT, EndDT) {
        return (
            <table className='StatisticalReport'>
                <tbody>
                    <tr>
                        <th>总接待次数/总转接次数</th>
                        {[...DateList.slice(StartDT - 1, EndDT)]}
                    </tr>
                    {data.map((value, key) => (
                        <tr key={key}>
                            <td>{value.userAccount}</td>
                            {Object.keys(value.receiveAndTransferInfo).map( (key,index)=>(
                                ((index+1)>=StartDT && (index+1)<=EndDT) ? <td key={index}>{value.receiveAndTransferInfo[key]}</td>:''
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    singleTable(data, StartDT, EndDT) {
        return (
            data.map((value, key) => (
                <div key={key}>
                    <div style={styles.text}>
                        帐号:{value.userAccount}
                    </div>
                    <table id="customers" className='StatisticalReport'>
                        <tbody>
                            <tr>
                                <th>时段/日期</th>
                                {[...DateList.slice(StartDT - 1, EndDT)]}
                            </tr>
                            {value.timeRangeInfoList.map((value, key) => {
                                return(
                                    <tr key={key}>
                                        <td>{value.timeRange}</td>
                                        {Object.keys(value).filter(val => val.indexOf('DayCount') >= 0).map((key,index) => (
                                            ((index+1)>=StartDT && (index+1)<=EndDT) ? <td key={index}>{value[key]}</td>:''
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))
        );
    }

    render() {
        const {
            reportData,
            StartDT,
            EndDT,
        } = this.props;
        if (reportData.length <= 0 ) return null;
        
        return(
            <div style={styles.wrapper}>
                {reportData.map(data => {
                    const thisYear = parseFloat(data.month.slice(0,4));
                    const thisMonth = parseFloat(data.month.slice(4));
                    let startIndex = 1;
                    let endIndex = moment(`${thisYear}-${thisMonth}-01`).daysInMonth();
                    if (StartDT.year === thisYear && StartDT.month === thisMonth) startIndex = StartDT.day;
                    if (EndDT.year === thisYear && EndDT.month === thisMonth) endIndex = EndDT.day;
                    return (
                        <div key={data.month} style={{ marginBottom: 20 }}>
                            <div style={{
                                fontSize: 25,
                                fontWeight: 'bold',
                                margin: 10,
                                color: 'black',
                            }}>{thisYear}年{thisMonth}月</div>
                            {this.totalTable(data.monthReportInfoList, startIndex, endIndex)}
                            {this.singleTable(data.monthReportInfoList, startIndex, endIndex)}
                        </div>
                    );
                })}
                
                
            </div>
        );
    }
}

const reduxHook = connect(
    state => ({
        reportData: state.Range.reportData,
    }),
    dispatch => bindActionCreators({
        ...Loading,
    }, dispatch),
  );

export default compose(
reduxHook,
)(Radium(RangeTabel));

