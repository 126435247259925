import JWT from 'jwt-decode';
import {
    GET_MEMBER_TOKEN_SUCCESS,
    GET_MEMBER_TOKEN_FAIL,
    CLEAR_MEMBER_TOKEN,
} from '../actions/Member';
import {
    UNAUTHORIZED
} from '../actions/ErrorHandle';

const initialData = (localStorage.accessToken ? JWT(localStorage.accessToken) : '');
export default ( state = {
    UserID: initialData.UserID,
    UserAccount: initialData.UserAccount,
    PositionLevelID: initialData.PositionLevelID,
    ErrorMessage: undefined,
    AccessToken: localStorage.accessToken ? localStorage.accessToken : '',
}, action) => {
    switch(action.type){
        case GET_MEMBER_TOKEN_SUCCESS:
            return {
                UserID: action.data.UserID,
                UserAccount: action.data.UserAccount,
                PositionLevelID: action.data.PositionLevelID,
                ErrorMessage: undefined,
                AccessToken: localStorage.accessToken ? localStorage.accessToken : '',
            };
        case GET_MEMBER_TOKEN_FAIL:
            return {
                ...state,
                ErrorMessage: action.error
            };
        case CLEAR_MEMBER_TOKEN:
            return {
                UserID: undefined,
                UserAccount: undefined,
                PositionLevelID: undefined,
                ErrorMessage: undefined,
                AccessToken: '',
            };
        case UNAUTHORIZED:
            return {
                UserID: undefined,
                UserAccount: undefined,
                PositionLevelID: undefined,
                ErrorMessage: undefined,
                AccessToken: '',
            };
        default:
            return state;
    }
}