import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ChatRoom from './ChatRoom.js';
import Profile from '../../components/Chat/Profile.js';

class MainChat extends Component {
    render() {
        const {
            show,
            memberData
        } = this.props;
        return (
            <div className="col-md-8 col-lg-7 heigh100 ">
                { (show && Object.keys(memberData).length !== 0) ?
                (
                    <div className="row">
                        <ChatRoom memberData={memberData}/>
                        <Profile memberData={memberData}/>
                    </div>
                ) :
                (null)}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    show: (state.MainChat.show !== '' && state.Chat.MemberID !== '') ? true : false,
    memberData: state.Chat.ChatList.find(el => el.showTitle === true) === undefined ? {} : state.Chat.ChatList.find(el => el.showTitle === true)
});

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MainChat);