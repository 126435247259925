import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ContentPage from './ContentPage';

class ContentContainer extends Component {
    render() {
        const { pageList } = this.props;

        return (
            <Fragment>
                <div className="tab-content">
                    {pageList.map((item, key) =>
                        <ContentPage
                            key={`ContentPage-${key}`}
                            thisPage={item}/>
                            )}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    pageContent: state.Template.pageContent,
    pageList: state.Template.pageList,
    nowPage: state.Template.nowPage,
});

export default connect(
    mapStateToProps,
)(ContentContainer);