import React, { Component } from 'react';
import {
    reduxForm,
  } from 'redux-form';
import {
    compose,
    bindActionCreators,
} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Select from "react-select";
import RangeTabel from './RangerTabel/RangeTabel.js';
import * as RangeAction from '../actions/Range.js';
import * as LoadingAction from '../actions/Loading.js';

const styles = {
    wrapper: {
        display:'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '35px',
    },
    inputOutter:{
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: '15px',
    },
    text: {
        border: 0,
        fontSize: '20px',
        fontWeight: 'bold',
        margin: '10px',
    },
    inputAccount: {
        width: '335px',
        height: '45px',
        border: '1px solid #999',
        borderRadius: '5px',
        fontSize: '20px',
    },
    button: {
        width: '100px',
        height: '50px',
        color: '#fff',
        backgroundColor: '#17a2b8',
        borderColor: '#17a2b8',
        borderRadius: '5px',
        fontSize: '17px',
        fontWeight: 'bold',
    },
    btnSent: {
        margin: '30px 0',
        display:'flex',
        justifyContent: 'center',
    },
    select: {
        width: '700px',
        height: '45px',
        borderRadius: '5px',
        fontSize: '20px',
    },
    timeText: {
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        margin: 5,
    },
    selectTimeRange: {
        width: '700px',
        height: '45px',
        border: '1px solid #999',
        borderRadius: '5px',
        fontSize: '20px',
        backgroundColor: '#fff',
    },

};


class Range extends Component {
    componentWillMount() {
        const {
            getCSAccount,
            initTableData,
        } = this.props;
        getCSAccount();
        initTableData();
        moment.tz.setDefault("Asia/Shanghai");
    }

    state = {
        moStDte: moment().startOf('month').set({ h: 4, m: 0 }).format().substring(0, 16),
        moEdDte: moment().set({ h: 4, m: 0 }).format().substring(0, 16),
        timeRage: 'DEFAULT',
      };

    searchData() {
        const {
            Range,
            getReport,
            LoadingShow,
        } = this.props;
        const {
            moStDte,
            moEdDte,
          } = this.state;
        const postData = {
            startDT: moment(moStDte),
            endDT: moment(moEdDte),
            userIDList: Object.values(Range.selectCSData).map(item => item.value),
        };
        getReport(postData);
        LoadingShow();
    }

    mohandleChange = ({ moStDte, moEdDte }) => {
        moStDte = moStDte || this.state.moStDte;
        moEdDte = moEdDte || this.state.moEdDte;
        const startFormat = moment.utc(moStDte, 'YYYY-MM-DD[T]hh:mm[Z]');
        const endFormat = moment.utc(moEdDte, 'YYYY-MM-DD[T]hh:mm[Z]');
        if (startFormat.isAfter(endFormat)) {
          this.setState({
            startDate: startFormat,
            endDate: endFormat,
            moStDte: startFormat.format().substring(0, 16),
            moEdDte: startFormat.format().substring(0, 16),
          });
        } if (endFormat.isBefore(startFormat)) {
          this.setState({
            startDate: startFormat,
            endDate: endFormat,
            moStDte: endFormat.format().substring(0, 16),
            moEdDte: endFormat.format().substring(0, 16),
          });
        } else {
          this.setState({
            startDate: startFormat,
            endDate: endFormat,
            moStDte: startFormat.format().substring(0, 16),
            moEdDte: endFormat.format().substring(0, 16),
          });
        }
      };
    handeMoSTDateChange = moStDte => this.mohandleChange({ moStDte: moStDte.target.value });
    handeMoEDDateChange = moEdDte => this.mohandleChange({ moEdDte: moEdDte.target.value });
    handleChange = (selectValue) => {
        const {
            updateCSData,
        } = this.props;
        updateCSData(selectValue);
    };

    timeRageChange(event) {
        switch (event.target.value) {
            case '1': {
              return this.setState({
                    moStDte: moment().startOf('month').set({ h: 7, minute: 30, second: 0 }).format().substring(0, 16),
                    moEdDte: moment().set({ h: 15, minute: 59, second: 0 }).format().substring(0, 16),
                    });
                }
            case '2': {
              return this.setState({
                    moStDte: moment().startOf('month').set({ h: 15, minute: 30, second: 0 }).format().substring(0, 16),
                    moEdDte: moment().set({ h: 23, minute: 59, second: 0 }).format().substring(0, 16),
                    });
                }
            case '3': {
                return this.setState({
                        moStDte: moment().startOf('month').set({ h: 23, minute: 30, second: 0 }).format().substring(0, 16),
                        moEdDte: moment().add(1, 'days').set({ h: 7, minute: 59, second: 0 }).format().substring(0, 16),
                        });
                    }
            case '4': {
                return this.setState({
                    moStDte: moment().startOf('month').set({ h: 9, minute: 0, second: 0 }).format().substring(0, 16),
                    moEdDte: moment().set({ h: 17, minute: 30, second: 0 }).format().substring(0, 16),
                    });
                }
            case '5': {
                return this.setState({
                        moStDte: moment().startOf('month').set({ h: 17, minute: 30, second: 0 }).format().substring(0, 16),
                        moEdDte: moment().add(1, 'days').set({ h: 2, minute: 0, second: 0 }).format().substring(0, 16),
                        });
                    }
            default:
              return;
        }
    }

    render() {
        const {
            Range,
        } = this.props;
        const {
            moStDte,
            moEdDte,
          } = this.state;
          const selectOptions = Range.CSData.map((item, key) => ({
            label: item.account,
            value: item.id,
        }));
        return (
            <div style={styles.wrapper}>
                <div style={{ display:'flex', justifyContent: 'center',flexDirection: 'column',}}>
                    <div style={styles.inputOutter}>
                        <p style={styles.text}>
                        期间：
                        </p>
                        <input
                            style={styles.inputAccount}
                            name="moStDte"
                            type="datetime-local"
                            value={moStDte}
                            onChange={this.handeMoSTDateChange} />
                        <span style={styles.timeText}>
                        至
                        </span>
                        <input
                            style={styles.inputAccount}
                            name="moEdDte"
                            type="datetime-local"
                            value={moEdDte}
                            onChange={this.handeMoEDDateChange} />
                    </div>
                    <div style={styles.inputOutter}>
                        <p style={styles.text}>
                        区间：
                        </p>
                        <select
                            defaultValue={'DEFAULT'}
                            onChange={this.timeRageChange.bind(this)}
                            style={styles.selectTimeRange}>
                            <option value="DEFAULT" disabled>时间快选</option>
                            <option value="1">早班(07:30~15:59)</option>
                            <option value="2">中班(15:30~23:59)</option>
                            <option value="3">晚班(23:30~07:59)</option>
                            <option value="4">台北早班(09:00~17:30)</option>
                            <option value="5">台北晚班(17:30~02:00)</option>
                        </select>
                    </div>
                    <div style={styles.inputOutter}>
                        <p style={styles.text}>
                        客服清单：
                        </p>
                        <div style={styles.select}>
                        <Select
                            name="CSAccount"
                            isMulti
                            placeholder="请选择..."
                            options={selectOptions}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.handleChange}
                            styles={{
                                ...styles,
                                control: (base, state) => ({
                                    ...base,
                                    border: '1px solid #999',
                                    borderColor: '1px solid #999',
                                    backgroundColor: '1px solid #999',
                                    color: '1px solid #999',
                                }),
                            }}
                        />
                        </div>
                    </div>
                    <div style={styles.btnSent}>
                        <button
                            type="button"
                            style={styles.button}
                            onClick={() => { this.searchData(); }}>
                            搜寻
                        </button>
                    </div>
                </div>
                <RangeTabel
                    StartDT={{
                        year: parseFloat(moment(moStDte).format('Y')),
                        month: parseFloat(moment(moStDte).format('M')),
                        day: parseFloat(moment(moStDte).format('D')),
                    }}
                    EndDT = {{
                        year: parseFloat(moment(moStDte).format('Y')),
                        month: parseFloat(moment(moEdDte).format('M')),
                        day: parseFloat(moment(moEdDte).format('D')),
                    }}/>
            </div>
        );
    }
}


const formHook = reduxForm({
  form: 'RANGE_FORM',
});

const reduxHook = connect(
  state => ({
    Range: state.Range,
  }),
  dispatch => bindActionCreators({
    ...RangeAction,
    ...LoadingAction,
  }, dispatch),
);

export default compose(
  reduxHook,
  formHook,
)(Range);